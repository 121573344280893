.breadContainer {
    display    : inline-block;
    // line-height: 50px;
    font-size: 14px;
    font-family: HelveticaNeue;
    margin-top: 17px;
    margin-bottom: 17px;
    .bread {
        padding: auto;
        display: inline-block;
    }

    .ant-divider{
        display: none;
    }

    .actionContainer {
        position: absolute;
        top     : 76px;
        right   : 32px;
        border-radius: 4px;
    }

    .divider {
        margin: 0px;
    }

    .breadHeader {
        display         : inline-block;
        background-color: #57c2e7;
        color           : red;
        margin-right    : 10px;
        margin-left     : 0px;
        width           : 3px;
        height          : 20px;
        vertical-align  : middle;
    }

    .breadcrumb-button {
        min-width: 100px;
        border-radius: 4px;
    }
}
@primary-color: #1880DE;