.ef-404-redirect{
    display: flex;
    flex-direction: column;
    align-items: center;
    .ef-description1{
        margin-top: 10px;
    }
    .ef-description2{
    }
    .ef-404-redirect-email{
        margin-top: 10px;
    }
}
@primary-color: #1880DE;