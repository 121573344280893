.ef-box-table{
    .ef-box-list-title{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 24px;
        .box-list-bar{
            width: 4px;
            height: 16px;
            border-radius: 2px;
            margin-right: 11px;
        }
        .box-list-title{
            height: 28px;
            font-size: 20px;
            font-weight: bold;
            color: #121736;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .ef-box-section{
        flex: 1;
        width: 100%;
        background: #FFFFFF;
        overflow: auto;
        font-size: 14px;
    }
}

// .ef-box-section{
//     .ant-table-cell{
//         padding: 8px 8px 8px 32px !important;
//     }
//     .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
//         content: none;
//     }
// }
@primary-color: #1880DE;