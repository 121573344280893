.ef-master-container {
    min-height: 600px;
    p {
        display: inline;
    }

    .item-wrapper {
        height: 40px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .item-label-container {
        display: inline;
        width  : 100px;
        float  : left;
    }

    .item-content-container {
        display    : inline;
        font-weight: bolder;
    }

    .acition-container {
        padding: 0px;
    }

    .update-button {
        width: 100px;
    }
    .upload-wrapper{
        margin-bottom: 10px;
    }

    .input-text {
        display            : inline;
        // background-color: #f1f1f1;
        width              : 100px;
    }

    .analyst-select {
        min-width: 100px;
    }

    .date-row {
        margin-bottom: 5px;
    }

    .check-group {
        width: 100%;
    }

    .area-title {
        font-weight   : bolder;
        padding-bottom: 10px;
    }

    .area-content {
        padding-bottom: 20px;
    }

    .setting-title-wrapper {
        padding-bottom: 20px;
        font-weight   : bolder;
    }

    .report-upload-wrapper {
        margin-bottom: 20px;
    }

    .report-checkbox-wrapper {
        margin-bottom: 20px;
    }

    .trademark-count-container {
        display         : inline;
        background-color: #57c2e7;
        padding-right   : 5px;
        padding-left    : 5px;
        border-color    : #57c2e7;
        color           : white;
        border-radius   : 5px;
        height          : 15px;
        line-height     : 15px;
        vertical-align  : middle;
    }
}

.ec-master-brand{
    padding: 24px;
    background-color: #F9F8FC;
    border-radius: 8px;
    display: flex;
    min-height: 600px;
    overflow: hidden;
    .ec-master-brand-menu{
        .master-brand-menu-item{
            width: 320px;
            display: flex;
            height: 48px;
            text-align: start;
            padding: 0px 16px;
            line-height: 48px;
            border-radius: 8px;
            color: #000;
            margin-bottom: 8px;
        }
        .master-brand-item-edit{
            height: 121px;
            width: 320px;
            background: #EDF5FD;
            border-radius: 8px;
            border: 1px solid #1880DE;
            padding: 16px;
            margin-bottom: 8px;
            position: relative;
            .edit-item{
                margin-bottom: 10px;
                max-width: 180px;
            }
            .edit-input-item{
                margin-bottom: 10px;
            }
            .trigger-item{
                float: right;
                cursor: pointer;
                color: #1880DE;
                margin-left: 20px;
            }
        }
        .master-brand-item-edit::after{
            content: '';
            position: absolute;
            top: 50%;
            right: -30px;
            transform: translateY(-50%) rotate(45deg);
            width: 18px;
            height: 18px;
            background-color: #fff;
        }
        .active{
            background: #E7F2FB;
            display: flex;
            justify-content: space-between;
            position: relative;
            .master-brand-item-trigger{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .trigger-item{
                    cursor: pointer;
                    height: 20px;
                    line-height: 20px;
                    color: #1880DE;
                }
            }
        }
        .active::after{
            content: '';
            position: absolute;
            top: 50%;
            right: -30px;
            transform: translateY(-50%) rotate(45deg);
            width: 18px;
            height: 18px;
            background-color: #fff;
          }
        .master-brand-menu-add{
            width: 320px;
            height: 48px;
            border-radius: 4px;
            border: 1px solid #1880DE;
            color: #1880DE;
            text-align: center;
            line-height: 48px;
            cursor: pointer;
        }
    }
    .ec-master-brand-content{
        flex: 1;
        margin-left: 20px;
        min-height: 552px;
        background-color: #ffffff;
        border-radius: 8px;
        .master-brand-wrap-content{
            padding: 10px 20px;
            width: 100%;  
        }
    }
}

.master-report-container{
    min-height: 600px;
    background-color: #ffffff;
    .master-report-wrap{
        padding: 10px;
    }
    .report-upload-wrapper{
        .upload-wrapper{
            display: block;
            margin-bottom: 12px;
            .upload-wrapper-button{
                border-radius: 4px;
                border: 1px solid rgba(183, 185, 194, 0.3);
                margin-right: 12px;
            }
            .upload-wrapper-button:focus{
                border: 1px solid #1880DE;
            }
            .upload-error{
                color: #FF5A5A;
                overflow: hidden;
                white-space: normal;
                margin: 8px 0px;
            }
        }
    }
    .master-report-box{
        padding: 4px 24px;
        border: 1px solid #EBEDF2;
        border-radius: 8px;
        margin-bottom: 20px;
        .master-report-footer{
            width: 100%;
            border-top: 1px solid #E6E8EB;
            padding-top: 16px;
            margin-bottom: 16px;
            .master-report-footer-operation{
                padding: 7px 16px;
                border-radius: 4px;
                border: 1px solid #1880DE;
                color: #1880DE;
            }
        }
    }
    .setting-title-wrapper {
        padding-bottom: 20px;
        font-weight   : bolder;
    }
    .item-wrapper {
        height: 40px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .item-label-container {
            display: inline;
            width  : 100px;
            float  : left;
        }
        .input-text {
            display            : inline;
            // background-color: #f1f1f1;
            width              : 100px;
            margin-left: 8px;
        }
    }
}

.ef-infringement-category-container{
    .ef-infringement-category-title{
        padding-bottom: 24px;
        margin-bottom: 20px;
        border-bottom: 1px solid #E6E8EB;
        display: flex;
        align-items: center;
        flex-direction: row;
        .ef-title-colorbar{
            display: inline-block;
            margin-right: 12px;
            width: 4px;
            height: 16px;
            background: #1880DE;
            border-radius: 2px;  
        }
        .ef-title-desc{
            display: inline-block;
        }
    }
    .ef-infringement-category-content{
        display: flex;
        flex-direction: row;
        .ec-menu{
            height: 100%;
                .master-brand-menu-item{
                    width: 240px;
                    height: 60px;
                    padding: 0px 16px;
                    border-radius: 8px;
                    color: #000;
                    margin-bottom: 8px;
                    .menu-title{
                        font-size: 14px;
                        height: 20px;
                        color: #121736;
                        display: inline-block;
                        max-width: 168px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .menu-subtitle{
                        margin-top: 4px;
                        height: 14px;
                        color: #888B9A;
                        display: inline-block;
                        max-width: 168px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .empty-content{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    color: #888B9A;
                    margin-top: 20px;
                    margin-bottom: 40px;
                }
                .flex-center{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                .master-brand-item-edit{
                    height: 193px;
                    width: 240px;
                    background: #EDF5FD;
                    border-radius: 8px;
                    border: 1px solid #1880DE;
                    padding: 16px;
                    margin-bottom: 8px;
                    position: relative;
                    .edit-item{
                        margin-bottom: 10px;
                        max-width: 180px;
                    }
                    .edit-input-item{
                        margin-bottom: 10px;
                    }
                    .trigger-item{
                        float: right;
                        cursor: pointer;
                        color: #1880DE;
                        margin-left: 20px;
                    }
                }
                .master-brand-item-edit::after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: -30px;
                    box-shadow: -4px 4px 8px 0px rgba(0, 0, 0, 0.05);
                    transform: translateY(-50%) rotate(45deg);
                    width: 18px;
                    height: 18px;
                    background-color: #fff;
                }
                .active{
                    background: #E7F2FB;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    position: relative;
                    .master-brand-item-trigger{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        .trigger-item{
                            cursor: pointer;
                            height: 20px;
                            line-height: 20px;
                            color: #1880DE;
                        }
                    }
                }
                .active::after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: -30px;
                    box-shadow: -4px 4px 8px 0px rgba(0, 0, 0, 0.05);
                    transform: translateY(-50%) rotate(45deg);
                    width: 18px;
                    height: 18px;
                    background-color: #fff;
                  }
                .master-brand-menu-add{
                    width: 240px;
                    height: 48px;
                    border-radius: 4px;
                    border: 1px solid #1880DE;
                    color: #1880DE;
                    text-align: center;
                    line-height: 48px;
                    cursor: pointer;
                }
            }
        }
        .ec-content{
            flex: 1;   
            background-color: #ffffff;
            box-shadow: -3px 0 15px rgba(0, 0, 0, 0.1);
            margin-left: 20px;
            min-height: 600px;
            .master-brand-wrap-content{
                padding: 10px 20px;
                width: 100%;  
            }
        }
}


.goto-button {
    margin-left: 12px;
}
@primary-color: #1880DE;