.user-new-container {
    padding: 20px;

    p {
        display: inline;
    }

    .item-wrapper {
        margin-bottom: 20px;

    }

    .item-label-container {
        display: inline;
        width  : 180px;
        float  : left;
    }

    .item-content-container {
        display    : inline;
        font-weight: bolder;
    }

    .acition-container {
        padding: 10px;
    }

    .update-button {
        width: 100px;
    }

    .input-text {
        // display            : inline;
        // background-color: #f1f1f1;
        width              : 60%;
    }


    .remarks-text {
        width              : 60%;
        height             : 100px;
        // background-color: #f1f1f1;
    }

    .company-select {
        min-width: 100px;
    }
}


.ef-box-footer{
    width: 100%;
    // margin-top: 24px;
    height: 28px;
    display: flex;
    flex-direction: row;
    flex-direction: start;
    font-size: 14px;
    font-weight: 400;
    .box-footer-edit{
        display: inline-block;
        height: 28px;
        border-radius: 2px;
        padding: 0px 12px;
        border: 1px solid #1470C5;
        color: #1470C5;
        line-height: 28px;
        margin-right: 12px;
    }
    .box-footer-cancel{
        display: inline-block;
        height: 28px;
        border-radius: 2px;
        padding: 0px 12px;
        border: 1px solid #DBDCE0;
        background: #FFFFFF;
        color: #595C72;
        line-height: 28px;
        margin-right: 12px;
    }
    .ant-form-item{
        height: 28px;
    }
}
@primary-color: #1880DE;