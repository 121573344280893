.ef-chart-news{
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #ebedf2;
    display: flex;
    width: 100%;
    flex-direction: column;
    .ef-chart-title {
        width: 100%;
        height: 72px;
        line-height: 72px !important;
        padding-left: 24px;    
        padding-right: 24px;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        a{
          color: #444444;
        }
        a:hover{
          color: #1880DE;
        }
    }
    .ef-chart-content {
        display: inline-block;
        flex: 1;
        padding: 0px 16px 16px 16px;
        box-sizing: border-box;
     
        .ef-chart-section{
          width: 100%;
          height: 100%;
          overflow: hidden;
          .ef-chart-section-item{
            display: flex;
            flex-direction: row;
            .ef-chart-item-column{
              flex: 1;
              display: flex;
              flex-direction: column;
              overflow: hidden;
              padding: 0px 10px 10px 10px;
              .ef-chart-item-link{
                margin-bottom: 12px;
                display: flex;
                flex-direction: row;
                // align-items: center;
                .dots{
                  display: inline-block;
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background: #888B9A;
                  margin-right: 10px;
                  position: relative;
                  top: 10px;
                }
                a{
                  font-weight: 400;
                  color: #121736;
                  font-size: 16px;
                }
              }
              .ef-chart-item-link:hover a{
                color: #1880DE;
              }
            }
            .ef-chart-item-column:not(:first-child) {
              margin-left: 40px;
            }
          }
        }
    }
}
@primary-color: #1880DE;