.iframe-section {
  position: relative;
}
.Loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.Loaded {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.example {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}
.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}

@primary-color: #1880DE;