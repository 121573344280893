.ipf-news-container{
    padding-left: 30px;
    padding-right: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    .news-item{
        color: black;
    }
}


.ef-ecsite-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .ef-ecsite-title{
        display: flex;
        flex-direction: row;
        align-items: center;
        .ef-ecsite-bar{
            margin-right: 11px;
            // display: inline-block;
        }
        .ef-ecsite-title{
            // display: inline-block;
        }
    }
}


.news-left{
    padding-right: 20px;
    .news-item{
        margin-bottom: 20px;
        .news-item-title{
            margin-bottom: 2px;
            display: block;
        }
    }
    border-right: 1px solid #E6E8EB;
    box-sizing: border-box;
}
.news-right{
    padding-right: 20px;
    .news-item{
        margin-bottom: 20px;
        .news-item-title{
            margin-bottom: 2px;
            display: block;
        }
    }
}
.news-pagination{
    margin-top: 16px;
    margin-bottom: 16px;
    float: right;
}
@primary-color: #1880DE;