.ef-chart-map{
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #ebedf2;
    display: flex;
    flex-direction: column;
    width: 100%;
    .ef-chart-title {
        width: 100%;
        height: 72px;
        line-height: 72px !important;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        .ef-chart-title-text{
            display: inline-block;
            margin-bottom: 0px !important;
        }
        .ef-chart-title-select{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 180px;
            height: 32px;
            background: #F4F5F9;
            border-radius: 4px;
            padding: 3px;
            position: relative;
            .ef-chart-title-select-item{
                display: inline-block;
                border-radius: 3px;
                flex: 1;
                width: 56px;
                height: 26px;
                position: relative;
                cursor: pointer;
                .ant-image{
                    position: absolute;
                    line-height: 20px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    .ant-image-img{
                        user-select: none;
                        -webkit-user-select: none;
                    }
                }
            }
            .ef-chart-title-select-slide{
                position: absolute;
                left: 0;
                top: 3px;
                border-radius: 3px;
                width: 56px;
                height: 26px;
                background: #ffffff;
                transition: left 0.3s ease-in-out;
            }
        }
      }
}
@primary-color: #1880DE;