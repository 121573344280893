.client-new-container{
    padding: 20px;
    p{
        display: inline;
    }
    .item-wrapper{
        margin-bottom: 20px;
    }

    .item-label-container{
        display: inline;
        width: 180px;
        float:left;
    }
    .item-content-container{
        display: inline;
        font-weight: bolder;
    }

    .acition-container{
        padding: 10px;
    }
    .update-button{
        width: 100px;
    }

    .input-text{
        display: inline;
        // background-color: #f1f1f1;
        width: 60%;
    }
    .remarks-text{
        width: 60%;
        height: 100px;
        // background-color: #f1f1f1;
    }

    .analyst-select{
        min-width: 100px;
    }

    .date-row{
        margin-bottom: 5px;
    }
}


.client-new-container-content{
    padding: 0px 32px;
}
.client-new-container-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 72px;
    border-top: 1px solid #E9EBF0;
    box-sizing: border-box;
    padding: 0px 32px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
@primary-color: #1880DE;