.ec-site-detail-container{
    margin: 15px;
    margin-top: 20px;
    .row{
        margin-bottom: 20px;
    }
}


.ef-ec-site-table{
    margin-top: 48px;
}

.ec-site-detail-modal{
    .ant-input-affix-wrapper {
        border-radius: 4px;
        border: 1px solid #E6E8EB;
    }
}
@primary-color: #1880DE;