.ef-box-list{
    .box-list-form{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .ef-box-list-title{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 16px;
            .box-list-bar{
                width: 4px; 
                height: 16px;
                border-radius: 2px;
                margin-right: 11px;
            }
            .box-list-title{
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .ef-box-section{
            flex: 1;
            width: 100%;
            background: #FFFFFF;
            // border-radius: 8px;
            // border: 1px solid #EBEDF2;
            border-top: 1px solid #EBEDF2;
            padding-top: 20px;
            display: block;
            font-size: 14px;
            overflow-y: visible;
            overflow-x: hidden;
            .box-section{
                display: flex;
                flex-direction: row;
                .item-label{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-right: 24px;
                    .item-label-container{
                        line-height: 35px;
                        display: flex;
                        flex-direction: column;
                        p{  
                            color: #888B9A;
                            white-space: nowrap;
                        }
                    }
                    .edit:not(:last-child){
                        margin-bottom: 12px;
                    }
                }
                .item-value{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    overflow-y: hidden;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    .item-value-container{
                        min-height: 35px;
                        line-height: 18px;
                        p{     
                            padding-top: 9px;
                            color: #121736;
                            white-space: pre-wrap;
                        }
                        .input-text{
                            // width: 100%;
                            min-height: 35px;
                            line-height: 32px;
                            padding: 0px 12px 0px 12px;
                            border-radius: 4px;
                            border: 1px solid #E6E8EB; 
                            box-sizing: border-box;
                            color: #121736;
                        }
                        .input-textarea{
                            padding-top: 7px;
                            padding-bottom: 7px;
                            min-height: 80px;
                            line-height: 20px;
                            resize: vertical !important;
                        }
                        .item-value-select{

                        }
                    }
                    .edit:not(:last-child){
                        margin-bottom: 12px;
                    }
                }
            }
        }
//         .ef-box-footer{
//             width: 100%;
//             margin-top: 20px;
//             height: 28px;
//             display: flex;
//             flex-direction: row;
//             flex-direction: start;
//             font-size: 14px;
//             font-weight: 400;
//             .box-footer-edit{
//                 display: inline-block;
//                 height: 28px;
//                 border-radius: 2px;
//                 padding: 0px 12px;
//                 border: 1px solid #1470C5;
//                 color: #1470C5;
//                 line-height: 28px;
//                 margin-right: 12px;
//             }
//             .box-footer-cancel{
//                 display: inline-block;
//                 height: 28px;
//                 border-radius: 2px;
//                 padding: 0px 12px;
//                 border: 1px solid #DBDCE0;
//                 background: #FFFFFF;
//                 color: #595C72;
//                 line-height: 28px;
//                 margin-right: 12px;
//             }
//             .box-footer-update{
//                 display: inline-block;
//                 height: 28px;
//                 border-radius: 2px;
//                 padding: 0px 12px !important;
//                 border: 1px solid #1880DE;
//                 background: #1880DE;
//                 color: #ffffff;
//                 line-height: 28px;
//                 margin-right: 12px;
//             }
//             .ant-form-item{
//                 height: 28px;
//             }
//         }
    }
}

// .ef-box-footer{
//     .ant-form-item-control-input{
//         min-height: 28px !important;
//     }
// }

@primary-color: #1880DE;