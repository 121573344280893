.task-start-url{
    // height: 200px;
    width: 500px;
}

.new-ec-crawler-container{
    margin: 15px;
    margin-top: 20px;
    .row{
        margin-bottom: 20px;
    }
}
@primary-color: #1880DE;