.ef-container {
  /* 自定义 Select 组件聚焦时的高亮边框样式 */
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: #1470c5; /* 设置边框颜色为蓝色 */
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); /* 设置边框阴影效果 */
    border-radius: 4px !important;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #1880de;
  }
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 4px;
}
.ant-picker{
  border-radius: 4px;
}
.ant-input{
  border-radius: 4px;
}


/* Select */
// 设置文字颜色以及大小
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-weight: 400;
  color: #595c72;
}

// 设置未选中的字体颜色
.ant-select-item {
  color: #595c72;
}

// 未选中背景颜色
.ant-select-dropdown {
  background: #ffffff;
}

.ant-select-item-option{
  // font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
// 选择时候的颜色
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f6f6f8;
  // font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

// 选中时的字体颜色 以及背景颜色
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #1880de;
  background: #ffffff;
  // font-size: 16px;
  font-weight: 400;
}

.ant-select-single .ant-select-selector{
  flex: 1;
}

/* 设置RangePicker */
.ant-picker.ant-picker-range.ant-picker-large.ant-picker-focused {
  border-color: #1470c5; /* 设置边框颜色为蓝色 */
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #1880de;
}

.ant-picker-range .ant-picker-active-bar {
  background: #1880de;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e7f4ff;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #56a7f0;
}


/* 设置table */
.ant-table-row{
  height: 48px;
}
.ant-table-cell{
  padding-top: 12px;
  padding-bottom: 12px;
  height: 48px;
}
.ant-table-wrapper{
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
}

/* 设置select */
.ant-select {
  color: #121736;
  .ant-select-selection-item{
    color: #121736 !important;
  }
}

.ant-select-multiple .ant-select-selection-item{
  // background: #e9f5ff;
}

/* 设置collapse */
.ant-collapse-header{
  box-shadow: inset 0px -1px 0px 0px #E9EAEC;
}
.ant-collapse-content-box{
  padding: 0 !important;
}

textarea{
  outline: none;
  padding: 5px 10px 5px 10px;
}

/* 设置Table */
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #ffffff;
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td{
  background: #f1fbff;
} 
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
  padding: 10px 10px;
}
.ant-table-fixed {
  table-layout: fixed;
}
.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}
.ant-table-cell{
  padding: 8px 8px 8px 20px !important;
}
.ant-table-selection-column.ant-table-cell{
  padding: 8px 8px 8px 8px !important;
}
.ant-table-cell-fix-right.ant-table-cell{
  padding: 8px 8px 8px 8px !important;
}

// 设置Modal
.ant-modal{
  max-width: 786px;
  max-height: 70%;
  display: flex;
  flex-direction: column;
  .ant-modal-content{
    flex-grow: 1;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    overflow: hidden;
    .ant-modal-header{
      height: 60px;
      padding: 16px 32px;
      border-radius: 8px;
    }
    .ant-modal-body{
      padding: 24px 32px;
      overflow: auto;
      .ant-form{
        width: 100%;
      }
    }
    .ant-modal-footer{
      height: 64px;
      border-radius: 8px;
      padding: 16px 32px;
      .ant-btn{
        border-radius: 4px;
      }
    }
  }
}

// 设置Popover
.ant-popover{
  .ant-popover-inner-content{
    padding: 13px 20px;
    .ant-btn{
      border-radius: 4px;
      height: 32px;
    }
  }
}

// 设置Upload
.ant-upload-list-item.ant-upload-list-item-done{
  border-radius: 8px;
  border: 1px solid #EBEDF2;
  height: 64px;
}
.ant-upload-list-item.ant-upload-list-item-uploading{
  .ant-progress-inner{
    margin-top: 5px;
    .ant-progress-bg{
      height: 3px !important;
      background: #1880DE;
      border-radius: 2px;
    }
  }
}
.ant-upload-list-item.ant-upload-list-item-error{
  border-radius: 8px;
  border: 1px solid #FF5A5A;
  height: 64px;
}
.ant-upload.ant-upload-select-picture-card {
  width: 148px;
  height: 148px;
  background: #ffffff;
}

// 设置Dropdown
.ant-dropdown{
  .ant-dropdown-menu{
    padding: 8px 0px;
    .ant-dropdown-menu-item{
      margin: 0px 8px;
      height: 32px;
    }
    .ant-dropdown-menu-item:hover{
      background: #F4F5F9;
      border-radius: 2px;
    }
  }
}
.ant-dropdown-menu-submenu {
  .ant-dropdown-menu{
    padding: 8px 0px;
    .ant-dropdown-menu-item{
      margin: 0px 8px;
      height: 32px;
    }
    .ant-dropdown-menu-item:hover{
      background: #F4F5F9;
      border-radius: 2px;
    }
  }
}

// 设置Input
.ant-input-disabled{
  color: rgba(0, 0, 0, 0.25) !important;
}

// 设置Popover
.ant-popover{
  .ant-popover-inner-content{
    .ef-chart-table-float-item{
      color: #121736;
      a{
        color: #121736;
      }
    }
    .ef-chart-table-float-item:hover{
      color: #1880DE;
      a{
        color: #1880DE;
      }
    }
  }
}
