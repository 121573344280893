.ef-header-container{
    height: 64px;
    width: 100%;
    background-color: white;
    padding-left: 10px;
    z-index: 0;
}

.ef-header-right-container{
    position: absolute;
    right: 50px;
}

.ef-header-logout-button{
    border-color: #57c2e7;
    color: #57c2e7;
    border-radius: 5px;
}
@primary-color: #1880DE;