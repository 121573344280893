.ef-c-searchbar-container {
    padding: 0px 10px 10px 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    p {
        display: inline;
        margin-right: 15px;
    }

    .item-group{
        display: inline;
        margin-right: 30px;
    }
    .date-container {
        padding: 5px;
        .date-items {
            min-width: 150px;
        }
    }
    .keyword-container{
        padding: 5px;
        .keyword-items{
            min-width: 150px;
            margin-right: 10px;
        }
        .search-word{
            display: inline;
            width: 300px;
            margin-right: 10px;
        }

        .search-type{
            min-width: 150px;
        }
    }

    .action-container{
        margin-top: 10px;
        .search-button{
            margin-right: 30px;
            width: 100px;
        }
        .reset-button{
            width: 100px;
        }
    }
}


@primary-color: #1880DE;