.ef-chart-ball {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ebedf2;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .ef-chart-title {
    width: 100%;
    height: 72px;
    line-height: 72px !important;
    padding-left: 24px;
    overflow: hidden;
    white-space: nowrap;
  }
  .ef-chart-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 16px 16px 16px;
    flex-direction: row;
    .ef-chart-section{
      width: 100%;
      .ef-chart-content-ball-section{
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      .ef-chart-content-left{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 20px;
        .ef-chart-content-left-section{
          position: relative;
          .ef-chart-ball-animation{
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 50%;
            background: #F5FAFF;
          }
          .ef-chart-ball-categorized{
            bottom: 50%;
            left: 50%;
            transform: translate(-50%,50%);
            position: absolute;
            text-align: center;
            .ef-chart-ball-number{
              font-size: 18px;
              font-family: 'Bebas';
              letter-spacing: 1px;
              color: #121736;
            }
          }
          .ef-chart-ball-infrigement{
            bottom: 10%;
            left: 50%;
            transform: translate(-50%,50%);
            position: absolute;
            text-align: center;
            .ef-chart-ball-number{
              font-size: 18px;
              font-family: 'Bebas';
              letter-spacing: 1px;
              color: #121736;
            }
          }
        }
      }
      .ef-chart-content-right{
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 20px;
        .ef-chart-content-right-content{
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 200px;
          .ef-chart-content-right-item{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 6px 0 6px 0;
            .ef-chart-ball-number{
                font-size: 14px;
                font-family: 'Bebas';
                letter-spacing: 1px;
                color: #121736;
            }
          }
        }
      }
    }
  }
}

@primary-color: #1880DE;