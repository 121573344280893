.notice-table-container {
    padding: 20px 10px 10px 10px;
}

.notice-search-container {
    padding: 20px 10px 10px 10px;
}

.notice-unviewd-row {
    background-color: #E4F1FC;
}

.notice-tableview-header {
    text-align: right;
    float: right;
    padding-right: 20px;
    padding-bottom: 4px;
}

.notice-table-header{
    margin-bottom: 21px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.notice-center{
    width: 100%;
}
@primary-color: #1880DE;