.ec-crawler-task-list-container{
    margin: 15px;
}

.ef-task-container{
    .ef-task-filter{
        float: right;
        margin-bottom: 16px;
    }
}
@primary-color: #1880DE;