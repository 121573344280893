.ef-chart-rank{
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #ebedf2;
    display: flex;
    flex-direction: column;
    width: 100%;
    .ef-chart-title {
        width: 100%;
        height: 72px;
        line-height: 72px !important;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        .ef-chart-title-text{
            display: inline-block;
            margin-bottom: 0px !important;
        }
        .ef-chart-title-right{
            height: 72px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .ef-chart-slide-section{
                height: 32px;;
                .ef-chart-title-select{
                    display: flex;
                    flex-direction: row !important;
                    justify-content: space-evenly;
                    align-items: center;
                    width: 180px;
                    height: 32px;
                    background: #F4F5F9;
                    border-radius: 4px;
                    padding: 3px;
                    position: relative;
                    .ef-chart-title-select-item{
                        display: inline-block;
                        border-radius: 3px;
                        flex: 1;
                        width: 56px;
                        height: 26px;
                        position: relative;
                        cursor: pointer;
                        .ant-image{
                            position: absolute;
                            line-height: 20px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            .ant-image-img{
                                user-select: none;
                                -webkit-user-select: none;
                            }
                        }
                    }
                    .ef-chart-title-select-slide{
                        position: absolute;
                        left: 0;
                        top: 3px;
                        border-radius: 3px;
                        width: 56px;
                        height: 26px;
                        background: #ffffff;
                        transition: left 0.3s ease-in-out;
                    }
                }
            }
            .ef-chart-title-dropdown{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .ef-chart-dropdown-describe{
                    display: inline-block;
                    height: 100%;
                    text-align: center;
                    margin-right: 10px;
                }
                .ef-chart-dropdown-items{
                    height: 32px;
                    width: 240px;
                }
            }
        }
    }
    .ef-chart-content {
        display: inline-block;
        flex: 1;
        padding: 0 16px 16px 16px;
        box-sizing: border-box;
        .ef-chart-section{
          width: 100%;
          display: flex;
          .ef-chart-table{
            flex: 1;
            overflow-y: auto;
            .ef-chart-table-item{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: 3px;
                .ef-chart-table-rank{
                    height: 19px;
                    width: 39px;
                    margin-right: 10px;
                    color: #888B9A;
                    text-align: center;
                    font-weight: bold;
                    font-family: HelveticaNeue;
                    font-size: 12px;
                }
                .ef-chart-rank-1{
                    background: rgba(24, 128, 222, 0.1);
                    color: #1880de;
                }
                .ef-chart-rank-2{
                    background: rgba(250, 172, 88, 0.1);
                    color: #FAAC58;
                }
                .ef-chart-rank-3{
                    background: rgba(0, 214, 245, 0.1);
                    color: #00D6F5;
                }
                .ef-chart-table-name{
                    flex: 1;
                    color: #121736;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;  
                }
                .ef-chart-table-name:hover{
                    color: #1880DE;
                }
                .ef-chart-table-float{
                    margin-right: 15px;
                    color: #000000;
                    box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.08);
                    border-radius: 4px;
                    border: 1px solid #F0F1F5;
                    padding: 16px 24px 16px 0px;
                    .ef-chart-table-float-item:not(:first-child){
                        margin-top: 14px;
                        .ef-chart-table-float-link{
                            height: 16px;
                            line-height: 16px;
                            display: block;
                            cursor: pointer;
                        }
                    }
                }
                .ef-chart-table-num{
                    margin-right: 10px;
                    color: #B7B9C2;
                    margin-right: 10px;
                    font-size: 14px;
                    font-family: HelveticaNeue-Bold, HelveticaNeue;
                    font-weight: bold;
                }
                .ef-chart-table-web{
                    border: 1px solid #F0F0F0;
                    padding: 0 6px 0 6px;
                    color: #1880DE;
                    cursor: pointer;
                    .ef-chart-table-web-pop{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
          }
        }
    }
}

.ef-chart-table-float-item{
    height: 30px;
    font-size: 14px;
    color: #121736;
    line-height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .ef-chart-table-float-num{
        width: 30px;
        height: 16px;
        font-size: 14px;
        font-weight: bold;
        color: #1880DE;
        margin-right: 11px;
        display: flex;
        justify-content: flex-end;
    }
    .ef-chart-table-float-link{
        display: block;
        font-size: 14px;
        line-height: 16px;
        height: 16px;
        cursor: pointer;
        margin-right: 24px;
    }
}
@primary-color: #1880DE;