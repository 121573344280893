.ef-chart-rate {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ebedf2;
  display: inline-block;
  width: 100%;
  .ef-chart-title {
    width: 100%;
    height: 72px;
    line-height: 72px !important;
    padding-left: 24px;
    overflow: hidden;
    white-space: nowrap;
  }
  .ef-chart-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 16px 16px 16px;
    flex-direction: row;
    .ef-chart-item {
      flex: 1;
      height: 100%;
      background: #f5faff;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .ef-chart-item-ele{
        width: 100%;
        position: relative;
        p{
          width: 100%;
          text-align: center;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        .ef-chart-ball-number{
          font-family: 'Bebas';
          letter-spacing: 1px;
          color: #121736;
          font-size: 24px;
        }
        .ant-image{
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
    .ef-chart-item:not(:first-child) {
      margin-left: 12px;
    }
  }
}

@primary-color: #1880DE;