.filterContainer {
    max-height: 400px;
    min-height: 80px;

    .listContainer {
        width   : 400px;
        height  : 300px;
        overflow: auto;

        .row-container {
            height     : 100%;
            width      : 100%;
            padding:20px;
        }
    }

    .checkBoxContainer {
        margin-left : 10px;
        margin-right: 10px;
    }
}
@primary-color: #1880DE;