.ec-list-container {
    padding: 0px 10px 10px 10px;
    .client-selector-container {
        padding   : 0px 0px 0px 15px;
        margin-top: 10px;

        .client-title {
            display     : inline;
            margin-right: 15px;
        }
    }

    .cell-wapper{
        width: 150px;
    }

}

.collapse-container{
    margin-top: 15px;
    margin-bottom: 15px;
}

.ec-list-view-listContainer {
    height  : 400px;
    overflow: auto;
    width   : 100%;

    .check-all-contaner {
        width : 100%;
        height: 40px;
    }
}

.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position  : absolute;
    width     : 2px;
    height    : 100%;
    bottom    : 0;
    right     : 0px;
    cursor    : col-resize;
    background: red;
    z-index   : 1;
}

// drawer
.ant-drawer-wrapper-body{
    overflow-x: hidden;
    .ant-drawer-body{
        box-sizing: border-box;
        overflow-x: hidden;
        padding: 24px;
    }
}
.ant-checkbox-group{
    box-sizing: border-box;
    width: 100%;
}
.ef-category{
    box-sizing: border-box;
    width: 784px;
    border-radius: 8px 8px 0px 0px;
    padding-bottom: 38px;
    display: flex;
    flex-direction: column;
    .ef-category-title-section{
        box-sizing: border-box;
        margin-bottom: 20px;
        display: flex;
        width: 720px;
        flex-direction: row;
        justify-content: space-between;
        .ef-category-checkAll{
            width: 90px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            white-space: nowrap;
            font-weight: 400;
            color: #121736;
            line-height: 20px;
        }
    }
    .ef-category-content{
        width: 100%;
        display: flex; 
        height: auto;
        margin-bottom: 38px;
    }
    .ef-category-split-line{
        width: 720px;
        height: 1px;
        background: #E6E8EB;
    }
}
.ef-category:not(:first-child){
    margin-top: 0px;
}

// Table
.custom-row-big{
    font-size: 15px;
    height: 32px !important;
    td{
        line-height: 32px !important;
        height: 32px !important;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
.custom-row-small{
    font-size: 13px;
    height: 16px !important;
    line-height: 24px !important;
    td{
        line-height: 16px !important;
        height: 16px !important;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
.ant-table-small .ant-table-tbody > tr.custom-row-big > td{
    padding: 5px 8px;
}
.ant-table.ant-table-small .ant-table-tbody > tr.custom-row-small > td{
    padding: 0px 8px;
}

// grid 





.ec-list-error {
    background-color: #F8DFDF;
}
.ef-column-edit{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    .ef-table-button{
        color: #595C72;
    }
    .ef-table-button:hover{
        color: #1880DE;
    }
}
.ef-popver-item{
    .ef-popver-item-title{
        line-height: 32px;
        cursor: pointer;
    }
    .ef-popver-item-title:hover{
        color: #1880DE;
        background: rgba(23,128,222,0.1);
    }
}


.ant-btn-text-hover, .ant-btn-text:focus{
    background: rgba(18,128,222,0.2);
}
.ant-btn-text-hover, .ant-btn-text:hover{
    background: rgba(18,128,222,0.1);
} 

.ef-ec-list{
    width: 100%;
    height: calc(100% - 12px);
}

.ef-table-marketplace {
    margin-top: 12px;
}
.ef-grid-marketplace {
    margin-top: 12px;
    width: 100%;
    .ef-grid-content{
        overflow: hidden; 
        height: 253px;
        .ef-grid-item{
            display: flex;
            flex-direction: row;
            padding: 12px 18px 0px 12px;
            border-radius: 8px;
            width: 100%;
            height: 100%;
            border: 1px solid #EEEEEE;
            overflow: hidden;
            .ef-grid-image{
                width: 175px;
                height: 100%;
                .ef-grid-item-image{
                    border-radius: 8px;
                    position: relative;
                }
                .ef-grid-item-link{
                    width: 100%;
                    color: #1880DE;
                    text-align: center;
                    margin-top: 20px;
                    display: block;
                }
            }
            .ef-grid-describe{
                flex: 65;
                height: 100%;
                padding-left: 16px;
                position: relative;
                overflow: hidden;
                .ef-grid-describe-title{
                    display: block;
                    font-size: 14px;
                    line-height: 20px;
                    max-height: 40px;
                    font-weight: 600;
                    color: #121736;
                    text-overflow: ellipsis;
                    overflow-y: hidden;
                    font-weight: 600;
                    min-width: 150px;
                    cursor: pointer;
                    // resize: none;
                    // outline: none;
                    // padding: none;
                    // border: none;
                }
                .ef-grid-describe-title:hover{
                    color: #1880DE;
                }
                .ef-grid-describe-price{
                    display: flex;
                    flex-direction: row;
                    margin-top: 10px;
                    .ef-grid-price-type{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        p{
                            font-size: 13px;
                            line-height: 13px;
                            height: 13px;
                            color: #000000;
                            display: inline-block;
                        }
                    }
                    .ef-grid-price-num{
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: column;
                        p{
                            display: inline-block;
                            font-family: 'Bebas';
                            font-size: 20px;
                            line-height: 17px;
                            height: 20px;
                            color: #000000;
                        }
                    }
                }
                .ef-grid-describe-detail{
                    font-size: 13px;
                    line-height: 18px;
                    font-family: HelveticaNeue;
                    color: #595C72;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: row;
                    margin-top: 12px;
                    flex-wrap: wrap;
                    max-height: 50px;
                    overflow: hidden;
                    .ef-grid-describe-split{
                        color: #D8D8D8;
                    }
                    a{
                        display: inline-block;
                        color: #595C72;
                    }
                    a:hover{
                        display: inline-block;
                        color: #1880DE;
                    }
                }
                .ef-grid-describe-select{
                    display: flex;
                    margin-top: 15px;
                    height: 32px;
                    .ef-grid-select{
                        flex: 1;
                        font-size: 14px !important;
                        display: flex;
                        .ant-select-selector{
                            width: 216px;
                            flex: 1 1 1 !important;
                            border: 1px solid #E6E8EB;
                            border-radius: 4px;
                        }
                    }
                    .ef-grid-button{
                        color: #595C72;
                        border-radius: 5px;
                        width: 32px;
                        height: 32px;
                        margin-left: 9px;
                        border: 1px solid #E6E8EB;
                        padding: 0 !important;
                    }
                    .ef-grid-button:hover{
                        color: #1880DE;
                    }
                }
                .ef-grid-describe-slide{
                    display: flex;
                    margin-top: 10px;
                    height: 32px;
                    .ef-grid-slide-item{
                        flex: 1;
                        min-width: 216px;
                        height: 56px;
                        box-sizing: border-box;
                        // display: flex;
                        // flex-direction: row;
                        color: #595C72;
                        position: relative;
                        padding: 5px 12px;
                        border-radius: 4px;
                        border: 1px solid #E6E8EB;
                        .ef-radio-group{
                            width: 100%;
                            height: 100%;
                            .ef-grid-slide-radio{
                                width: 50%;
                            }
                        }
                        .ef-grid-slide-item-section{
                            display: inline-block;
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            .ef-grid-slide-active{
                                display: inline-block;
                                flex: 1;
                                font-size: 11px;
                                line-height: 22px;
                                height: 22px;
                                color: #ffffff;
                                cursor: pointer;
                                white-space: nowrap;
                                background: #1880DE;
                                border-radius: 3px;
                                text-align: center;
                            }
                            .ef-grid-slide-wait{
                                display: inline-block;
                                flex: 1;
                                text-align: center;
                                line-height: 22px;
                                height: 22px;
                                font-size: 11px;
                                cursor: pointer;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                color: #595C72;
                            }
                            .ef-grid-slide-gap{
                                width: 1px;
                                color: #D6D6D6;
                                line-height: 1.5;
                                font-weight: 400;
                                font-family: 'HelveticaNeue';
                            }
                        }
                    }
                    .ef-grid-button{
                        color: #595C72;
                        border-radius: 5px;
                        width: 32px;
                        height: 32px;
                        margin-left: 9px;
                        border: 1px solid #E6E8EB;
                        padding: 0 !important;
                    }
                    .ef-grid-button:hover{
                        color: #1880DE;
                    }
                }
            }
            .ef-grid-describe-cover{
                position:absolute;
                top:16;
                height: 229px;
                // width: 100%;
                box-sizing: border-box;
                background: #ffffff;
                box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.1), 0px 3px 6px -4px rgba(0,0,0,0.12);
                border-radius: 10px;
                box-sizing: border-box;
                padding: 12px;
                overflow: hidden;
                .ef-grid-describe-textArea{
                    resize: none;
                    overflow: hidden;
                    color: #000000 !important;
                    outline: none;
                    overflow: auto;
                    border: none !important;
                    background: #F4F5F9;
                    border-radius: 6px;
                    opacity: 0.7;
                    height: 161px;
                    box-sizing: border-box;
                    margin-bottom: 12px;
                }
                .ef-grid-describe-textArea:focus{
                    box-sizing: border-box;
                    color: #000000 !important;
                    outline: none;
                    border: none !important;
                }
                .ef-grid-describe-control{
                    float: right;
                    white-space: nowrap;
                    .ef-grid-describe-cancel{
                        border-radius: 4px;
                    }
                    .ef-grid-describe-confirm{
                        background: #1880DE;
                        color: #ffffff;
                        margin-left: 8px;
                        // margin-right: 16px;
                        border: none !important;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}

.ef-filter-section{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .ef-filter-section-one{
        margin-bottom: 32px;
        padding-bottom: 32px;
        width: 100%;
        .ef-filter-title{
            margin-bottom: 18px;
        }
        .ef-filter-content{
            
        }
        .ef-filter-content-item{
            padding:6px 16px 6px 16px;
            height: 32px;
            border-radius:16px;
            border: 1px solid #dee9ee;
            background: #ffffff;
            color: #9c9eb1;
            font-size: 14px;
            font-family: HelveticaNeue;
            line-height: 16px;
            margin-right: 8px;
            margin-bottom: 8px;
            cursor: pointer;
            transition: color border 0.3s ease-out;
        }
        .ef-filter-content-item:hover{
            border: 1px solid #1880DE;
            color: #1880DE;
        }
        .ef-filter-content-item-active{
            color: #1880DE;
            background: #e7f2f8;
            border: 1px solid #1880DE;
        }
        .ef-filter-content-item-wait{
            background: #dddddd;
            cursor: not-allowed;
            pointer-events: none;
        }
    }
    .ef-filter-section-one:not(:last-child) {
        border-bottom: 1px solid #E6E8EB;
    }
}

.ef-modal-batch-modify{
    // width: 786px;
    background: #FFFFFF;
    box-shadow: 0px 12px 48px 16px rgba(0,0,0,0.03), 0px 9px 28px 0px rgba(0,0,0,0.05), 0px 6px 16px -8px rgba(0,0,0,0.08);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 0px !important;
    .ant-modal-content{
        width: 100% !important;
        .ant-modal-body{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .ant-modal-body{
        display: flex;
        flex-direction: row;
        padding: 0px!important;
        overflow: hidden !important;
        .ef-model-select{
            border-right: 1px solid #f5f5f5;
            height: 535px;
            flex: 1;
            display: inline-block;
            padding-top: 24px;
            padding-left: 32px;
            overflow-y: auto;
            overflow-x: hidden;
            .ef-model-select-section{
                margin-bottom: 36px;
                width: 100%;
                display: flex;
                flex-direction: column;
                .ef-model-select-title{
                    display: block;
                    line-height: 36px;
                    height: 36px;
                    margin-bottom: 10px;
                }
                .ef-model-select-content{
                    display: flex;
                    flex-direction: column;
                    height: 34px;
                }
            }
        }
        .ef-model-modify{
            height: 535px;
            flex: 1;
            display: inline-block;
            overflow: auto;
            padding-top: 24px;
            padding-left: 24px;
            .ef-model-modify-section{
                margin-bottom: 36px;
                width: 100%;
                display: flex;
                flex-direction: column;
                .ef-model-modify-title{
                    display: block;
                    line-height: 36px;
                    margin-bottom: 10px;
                    height: 36px;
                }
                .ef-modify-item{
                    display: block;
                    .ef-modify-item-desc{
                        display: block;
                        margin-bottom: 7px !important;
                        color: #888B9A;
                    }
                    .edit{
                        color: #121736;
                        font-size: 14px;
                    }
                }
                .ef-modify-item:not(:last-child){
                    margin-bottom: 24px;
                }
            }
        }
    }
}

.ef-empty-content{
    width: 100%;
    height: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    .ef-empty-content-title{
        display: block;
        margin-top: 12px !important;
        height: 26px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #121736;
        line-height: 26px;
    }
    .ef-empty-content-text{
        display: block;
        margin-top: 6px !important;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888B9A;
        line-height: 16px;
    }
}

.ant-popover-arrow{
    width: 0px;
    height: 0px;
}
.ant-popover-inner-content{
   padding: 6px !important;
   margin: 0px;
}
@primary-color: #1880DE;