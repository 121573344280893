.ef-chart-horizontal-bar{
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #ebedf2;
    display: flex;
    width: 100%;
    flex-direction: column;
    .ef-chart-title {
        width: 100%;
        height: 72px;
        line-height: 72px !important;
        padding-left: 24px;    
        overflow: hidden;
        white-space: nowrap;
    }
    .ef-chart-content {
        display: inline-block;
        flex: 1;
        padding: 0 16px 16px 16px;
        box-sizing: border-box;
        .ef-chart-section{
          width: 100%;
        }
    }
}
@primary-color: #1880DE;