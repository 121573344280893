
.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position              : absolute;
    width                 : 10px;
    height                : 38px;
    background-color      : rgb(250, 250, 250);
    background-repeat     : no-repeat;
    background-origin     : content-box;
    box-sizing            : border-box;
    // background-image   : url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position   : bottom right;
    padding               : 0 3px 3px 0;
}

.react-resizable-handle-sw {
    bottom   : 0;
    left     : 0;
    cursor   : sw-resize;
    transform: rotate(90deg);
}

.react-resizable-handle-se {
    bottom: 0;
    right : 0;
    cursor: se-resize;
}

.react-resizable-handle-nw {
    top      : 0;
    left     : 0;
    cursor   : nw-resize;
    transform: rotate(180deg);
}

.ef-resizable-table-container {
    .ant-table.ant-table-small .ant-table-title,
    .ant-table.ant-table-small .ant-table-thead>tr>th,
    .ant-table.ant-table-small .ant-table-tbody>tr>td {
        padding: 8px 28px 8px 8px;
    }
}

.react-resizable-handle-ne {
    top      : 0;
    right    : 0;
    cursor   : ne-resize;
    transform: rotate(270deg);
}

.react-resizable-handle-w,
.react-resizable-handle-e {
    top       : 50%;
    margin-top: -10px;
    cursor    : ew-resize;
}

.react-resizable-handle-w {
    left     : 0;
    transform: rotate(135deg);
}

.react-resizable-handle-e {
    right    : 0;
    transform: rotate(315deg);
}

.react-resizable-handle-n,
.react-resizable-handle-s {
    left       : 50%;
    margin-left: -10px;
    cursor     : ns-resize;
}

.react-resizable-handle-n {
    top      : 0;
    transform: rotate(225deg);
}

.react-resizable-handle-s {
    bottom   : 0;
    transform: rotate(45deg);
}

.custom-row-big{
    font-size: 15px;
    height: 32px !important;
    td{
        line-height: 32px !important;
        height: 32px !important;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
.custom-row-small{
    font-size: 13px;
    height: 16px !important;
    line-height: 24px !important;
    td{
        line-height: 16px !important;
        height: 16px !important;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.ant-table-small .ant-table-tbody > tr.custom-row-big > td{
    padding: 5px 20px !important;
}
.ant-table.ant-table-small .ant-table-tbody > tr.custom-row-small > td{
    padding: 0px 20px !important;
}
@primary-color: #1880DE;