.ef-search-container{
    padding: 10px 0 10px 21px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    .ef-search-bar-item{
        border-radius: 4px;
    }
    .ant-form-item{
        margin-bottom: 0 !important;
    }
    .ef-search-container-label{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: end;
        width: max-content;
        margin-right: 12px;
        .ef-search-container-item{
            height: 40px;
            line-height: 40px;
        }
    }
    .ef-search-container-edit{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: start;
        width: max-content;
        margin: right 12px;
        .ef-search-container-item{
            height: 40px;
            line-height: 40px;
        }
        .ef-search-edit-item{
            display: inline-block;
            width: 240px;
            height: 40px;
            margin-left: 8px;
        }
        .ef-search-edit-item:first-child{
            margin-left: 0px;
        }
    }
}
@primary-color: #1880DE;