.client-list-container{
    padding: 0px;
    .result-table{
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
    }
}

.client-list-container {
    width: 100%;
    .client-list-table-header{
        float: right;
        margin-bottom: 10px;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
@primary-color: #1880DE;