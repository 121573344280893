.ec-detail-container {
  padding: 20px;

  p {
    display: inline;
  }

  .scrape-info-row {
    margin-top: 10px;
  }

  .tabs-container {
    margin-left: 10px;
    margin-right: 10px;
  }

  .section-container {
    margin-bottom: 40px;
  }

  .acition-container {
    padding: 10px;
  }

  .update-button {
    width: 100px;
    margin-left: 12px;
  }

  .select-box {
    width: 60%;
  }

  .input-text {
    // display: inline;
    // background-color: #f1f1f1;
    width: 60%;
  }

  .input-textarea {
    // display: inline;
    // background-color: #f1f1f1;
    width: 80%;
  }

  .process-container {
    margin-top: 30px;
  }

  .process-container-hide {
    display: none;
    margin-top: 30px;
  }

  .search-site {
    margin-left: 10px;
  }

  .description-text {
    height: 100px;
  }

  .iframe-detail {
    float: right;
  }

  .pagination-container {
    display: inline;
    width: 60%;
    float: right;
    text-align: right;
  }
}

.iframe-section {
  position: relative;
  width: 100%;
  height: 300px;
}
.Loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.Loaded {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.example {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}
.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}


.ant-form-item{
  padding-bottom: 0;
  margin-bottom: 0;
}

.ef-detail-container{
  width: 100%;
  .ef-detail-content{
    width: 100%;
    display: flex; 
    flex-direction: row;
    .ef-detail-scrape-section{
      border-radius: 8px;
      overflow-x: hidden;
      .ef-detail-scrape{
        width: 480px;
        background: #F5F7FA;
        color: #000;
        padding: 32px 24px 24px 24px;
        .ef-detail-describe{
          font-size: 26px;
          font-weight: 600;
          color: #121736;
          line-height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 24px;
        }
        .ef-detail-sold-detail{
          display: flex;
          flex-direction: row;
          height: 40px;
          margin-bottom: 20px;
          .ef-detail-currency{
            display: inline-block;
            font-size: 20px;  
            height: 22px;
            line-height: 22px;
            color: #121736;
          }
          .ef-detail-price{
            display: inline-block;
            font-size: 32px;
            height: 32px;
            line-height: 32px;
            font-family: Bebas;
            color: #121736;
            letter-spacing: 1px;
          }
        }
        .ef-detail-sold{
          display: inline-block;
          margin-right: 8px;
          .ef-detail-sold-inventory{
            font-size: 18px;
            color: #121736;
            line-height: 22px;
          }
          .ef-detail-sold-number{
            font-size: 18px;
            color: #121736;
            line-height: 22px;
          }
        }
        .ec-detail-region{
          display: flex;
          flex-wrap: wrap;
          margin-top: 14px;
          .ef-detail-region-item{
            margin-right: 10px;
            margin-top: 12px;
          }
        }
        .ef-detail-site{
          line-height: 32px;
          height: 32px;
          background: #E7EAF0;
          border-radius: 2px;
          max-width: 432px;
          overflow: hidden;
          color: #595C72;
          display: flex;
          flex-direction: row;
          .ef-detail-region-icon{
            display: inline-block;
            width: 13px;
            height: 13px;
            margin-left: 9px;
            margin-right: 9px;
          }
          .ef-detail-region-text{
            display: inline-block;
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .ef-detail-country{
          line-height: 32px;
          height: 32px;
          background: #E7EAF0;
          border-radius: 2px;
          color: #595C72;
          max-width: 432px;
          overflow: hidden;
          display: flex;
          flex-direction: row;
          .ef-detail-region-icon{
            display: inline-block;
            width: 13px;
            height: 13px;
            margin-left: 9px;
            margin-right: 9px;
          }
          .ef-detail-region-gap{
            display: inline-block;
          }
          .ef-detail-region-text{
            display: inline-block;
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .ef-detail-seller{
          line-height: 32px;
          height: 32px;
          max-width: 432px;
          overflow: hidden;
          background: #E7EAF0;
          border-radius: 2px;
          color: #595C72;
          display: flex;
          flex-direction: row;
          .ef-detail-region-icon{
            display: inline-block;
            width: 13px;
            height: 13px;
            margin-left: 9px;
            margin-right: 9px;
          }
          .ef-detail-region-text{
            white-space: nowrap;
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 32px;
            display: inline-block;
            color: #595C72;
          }
          .ef-detail-region-text:hover{
            color: #1880DE;
          }
        }
        .ef-detail-url{
          margin-top: 32px;
          .ef-detail-link{
            display: inline-block;
            margin-right: 32px;
            color: #1880DE;
          }
        }
        .ef-detail-screenshot{
          margin-top: 48px;
          color: #595C72;
          .ef-detail-screenshot-title {
            height: 17px;
            font-size: 15px;
            margin-bottom: 14px;
          }
          .ef-detail-screenshot-upload{
            display: flex;
            .ef-detail-upload-add{
              color: #121736;
            }
          }
        }
        .ef-detail-screenshot-readOnly{
          margin-top: 48px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .ef-detail-screenshot-readOnly-item{
            display: inline-block;
            margin-right: 8px;
            margin-bottom: 8px;
          }
        }
        .ef-detail-other{
          margin-top: 24px;
          margin-bottom: 32px;
          color: #595C72;
          .ef-detail-desc{
            line-height: 22px;
            font-size: 15px;
            display: block;
            p{
              display: inline-block;
            }
          }
          .ef-detail-desc:not(:first-child) {
            margin-top: 4px;
            p{
              display: inline-block;
            }
          }
        }
      }
    }
    .ef-detail-information{
      flex: 1;
      background: #ffffff;
      color: #000;
      padding: 0px 0px 32px 32px;
      .ef-detail-Categorized{
        width: 100%;
        .ef-detail-title{
          font-size: 20px;
          font-family: HelveticaNeue-Bold, HelveticaNeue;
          font-weight: bold;
          color: #121736;
          line-height: 25px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .ef-detail-title-bar{
            width: 4px;
            height: 16px;
            background: #1880DE;
            border-radius: 2px;
            display: inline-block;
          }
          .ef-detail-title-desc{
            display: inline-block;
            margin-left: 6px;
          }
        }
        .ef-detail-title:not(:first-child){
          margin-top: 64px;
        }
        .ef-detail-content-category{
            .ef-detail-category-item-title{
              margin-top: 24px;
              font-size: 14px;
              color: #595C72;
              // line-height: 14px;
            }
            .ef-detail-category-item-slide{
              padding-left: 3px;
              padding-right: 3px;
              border: 1px solid #d9d9d9;
            }
            .ef-detail-category-item-state{
              border-radius: 4px;
              box-sizing: border-box;
              border: 1px solid #d9d9d9;
              padding: 0px 10px;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              height: auto;
              flex-wrap: wrap;
              margin-top: 13px;
              .ef-detail-state-item{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                line-height: 14px;
                height: 32px;
                letter-spacing: 0px;
                margin-right: 56px;
                .ef-detail-state-desc{
                  display: inline-block;
                  font-size: 16;
                  line-height: 16px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
                .ef-detail-state-true{
                  
                  display: inline-block;
                  background: #1880DE;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  margin-left: 8px;
                }
                .ef-detail-state-false{
                  display: inline-block;
                  background: #FF8C45;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  margin-left: 8px;
                }
              }
            }
            .ef-detail-category-item-action{
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: 13px;
              border-radius: 4px;
              font-size: 16px;
              font-weight: 400;
              color: #121736;
              box-sizing: border-box;
              .ef-detail-slide-item-section{
                display: inline-block;
                width: 25%;
                display: flex;
                flex-direction: row;
                color: #121736;
                .ef-detail-slide-item-one{
                  width: 100%;
                  font-size: 14px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  .ef-detail-slide-active{
                    display: inline-block;
                    flex: 1;
                    font-size: 14px;
                    line-height: 32px;
                    // margin-top: 1px;
                    height: 32px;
                    color: #ffffff;
                    cursor: pointer;
                    background: #1880DE;
                    border-radius: 3px;
                    text-align: center;
                  }  
                  .ef-detail-slide-wait{
                    display: inline-block;
                    flex: 1;
                    text-align: center;
                    line-height: 32px;
                    // margin-top: 1px;
                    height: 32px;
                    font-size: 14px;
                    cursor: pointer;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    color: #595C72;
                  }
                  .ef-detail-slide-gap{
                    width: 1px;
                    line-height: 32px;
                    margin-top: 3px;
                    height: 32px;
                    color: #D6D6D6;
                    font-weight: 400;
                    margin-right: 1px;
                  }
                }
              }
            }
        }
      }
    }
  }
  .ef-detail-footer{
    margin-top: 59px;
    height: 80px;
    width: 100%;
    box-shadow: inset 0px 1px 0px 0px #E9EBF0; 
    .ef-detail-footer-control{
      display: flex;
      height: 100%;
      float: right;
      flex-direction: row;
      align-items: center;
      .ef-detail-footer-reset{
        margin-right: 16px;
      }
    }
  }
}

.ef-detail-item-hide{
  width: 0;
  height: 0 !important;
  overflow: hidden;
}
.ef-popover-item:not(:first-child){
  margin-top: 10px;
}
.ef-popover-item-title{
  font-size: 14px;
}
.ef-popover-item-value{
  font-size: 12px;
}
.ef-scrape-extend , .ef-scrape-shrink{
  color: #595C72;
  width: 100%;
  background: transparent;
  height: 32px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ef-scrape-extend:hover , .ef-scrape-shrink:hover{
  background: rgba(24, 128, 222, 0.15);
  color: #1880DE;
}

.ef-detail-cover{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5)
}
.ef-detail-loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%)
}

.ef-detail-image-error{
  width: 480px;
  height: 480px;
  background: #F4F5F9;
  display: flex;
  justify-content: center;
  align-items: center;
}
@primary-color: #1880DE;