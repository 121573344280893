.master-container {
    padding: 20px;

    p {
        display: inline;
    }

    .item-wrapper {
        margin-bottom: 20px;
    }

    .item-label-container {
        display: inline;
        width  : 100px;
        float  : left;
    }

    .item-content-container {
        display    : inline;
        font-weight: bolder;
    }

    .acition-container {
        padding: 0px;
    }

    .update-button {
        width: 100px;
    }
    .upload-wrapper{
        margin-bottom: 10px;
    }

    .input-text {
        display            : inline;
        // background-color: #f1f1f1;
        width              : 100px;
    }

    .analyst-select {
        min-width: 100px;
    }

    .date-row {
        margin-bottom: 5px;
    }

    .check-group {
        width: 100%;
    }

    .area-title {
        font-weight   : bolder;
        padding-bottom: 10px;
    }

    .area-content {
        padding-bottom: 20px;
    }

    .setting-title-wrapper {
        padding-bottom: 20px;
        font-weight   : bolder;
    }

    .report-upload-wrapper {
        margin-bottom: 20px;
    }

    .report-checkbox-wrapper {
        margin-bottom: 20px;
    }

    .goto-button {
        margin-left: 14px;
    }

    .trademark-count-container {
        display         : inline;
        background-color: #57c2e7;
        padding-right   : 5px;
        padding-left    : 5px;
        border-color    : #57c2e7;
        color           : white;
        border-radius   : 5px;
        height          : 15px;
        line-height     : 15px;
        vertical-align  : middle;
    }
}



.ef-ecsite-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .ef-ecsite-title{
        display: flex;
        flex-direction: row;
        align-items: center;
        .ef-ecsite-bar{
            margin-right: 11px;
            // display: inline-block;
        }
        .ef-ecsite-title{
            // display: inline-block;
        }
    }
}
@primary-color: #1880DE;