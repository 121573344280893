.test-purchase-container{
    padding: 10px;
}


.test-purchase-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .test-purchase-title{
        display: flex;
        flex-direction: row;
        align-items: center;
        .test-purchase-bar{
            margin-right: 11px;
            // display: inline-block;
        }
        .test-purchase-title{
            // display: inline-block;
        }
    }
}


.test-purchase-form-item{
    width: 200px !important;
}

.test-purchase-modal{
    .ant-input-affix-wrapper {
        border-radius: 4px;
        border: 1px solid #E6E8EB;
    }
}
@primary-color: #1880DE;