@import './ef-antd.less';
body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: 'Meiryo UI', 'Meiryo', 'ヒラギノ角ゴ Pro',
    'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'Osaka', 'ＭＳ Ｐゴシック',
    'MS PGothic', 'sans-serif', 'Bebas'; 
}

html {
  height: 100%;
  box-sizing: border-box;
}
#root {
  height: 100%;
}
p {
  margin-top: 0px;
  margin-bottom: 0px;
}
/* Global Style */
@primary-color: #1880DE;

// fontFamily
  .ef-text-tip {
    font-size: 10px;
    line-height: 12px;
    color: #595c72;
  }
  .ef-text-describe {
    font-size: 12px;
    line-height: 14px;
    color: #595c72;
  }
  .ef-text-normal {
    font-size: 14px;
    line-height: 16px;
    color: #595c72;
  }
  .ef-text-regular {
    font-size: 16px;
    line-height: 18px;
    color: #595c72;
  }
  .ef-text-block {
    font-size: 18px;
    line-height: 22px;
    color: #595c72;
  }
  .ef-text-bold {
    font-size: 20px;
    line-height: 28px;
    color: #121736;
  }

  .ef-title-tip {
    font-size: 14px;
    line-height: 16px;
    color: #121736;
    font-weight: bold;
  }
  .ef-title-describe {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    color: #121736;
  }
  .ef-title-normal{
    font-size: 18px;
    line-height: 24px;
    color: #121736;
    font-weight: bold;
  }
  .ef-title-regular {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: #121736;
  }
  .ef-title-block {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: #121736;
  }
  .ef-title-bold {
    font-size: 32px;
    line-height: 48px;
    font-weight: bold;
    color: #121736;
  }

  .ef-fontFamily-en{
    font-family: 'HelveticaNueue'
  }
  .ef-fontFamily-ja{
    font-family: 'メイリオ','Hiragino Kaku Gothic Pro', 'MS PGothic','ヒラギノ角ゴ ProN W3','sans-serif';
  }


  // primary button
  .ef-button-color {
    letter-spacing: 1px;
    min-width: 100px;
    height: 40px;
    background: #1880de !important;
    box-sizing: border-box;
    color: #ffffff;
    border-radius: 4px;
    padding: 0 12px;
    border: none !important;
  }
  .ef-button-color:hover {
    color: #3fa0eb !important;
    background: #1470c5 !important;
  }
  .ef-button-color:focus{
    color: #ffffff !important;
    background: #1880de !important;
  }

  .ef-button-color-small {
    .ef-button-color;
    min-width: 80px;
    height: 32px;
  }
  .ef-button-color-small:hover {
    color: #3fa0eb !important;
    background: #1470c5 !important;
  }
  .ef-button-color-small:focus {
    color: #ffffff !important;
    background: #1880de !important;
  }

  .ef-button-linear {
    min-width: 100px;
    height: 40px;
    background: none;
    border: #1880de 1px solid;
    box-sizing: border-box;
    color: #1572c7;
    border-radius: 4px;
    padding: 0 12px;
  }
  .ef-button-linear-small {
    .ef-button-linear;
    min-width: 80px;
    height: 32px;
  }

  .ef-button-secondary {
    min-width: 100px;
    height: 40px;
    background: none;
    border-radius: 4px; 
    box-sizing: border-box;
    border: #DBDCE0 1px solid;
    color: #595C72;
    padding: 0 12px;
  }
  .ef-button-secondary:hover{
    color: #3fa0eb !important;
    border: #1880de 1px solid;
  }
  .ef-button-secondary:focus{
    color: #595C72 !important;
    border: #DBDCE0 1px solid;
  }
  .ef-button-secondary-small {
    .ef-button-secondary;
    min-width: 80px;
    height: 32px;
  }
  .ef-button-secondary-small:hover{
    color: #3fa0eb !important;
    border: #1880de 1px solid;
  }
  .ef-button-secondary-small:focus{
    color: #595C72 !important;
    border: #DBDCE0 1px solid;
  }

  .ef-active-color {
    color: #1880de;
  }
  .ef-bar-active{
    background: #1880DE;
    width: 4px;
    height: 16px;
    border-radius: 2px;
  }

  // side Button
  .ef-sidefun{
    border: transparent;
    outline: none;
    border-radius: 5px;
    padding: 0px;
    display: flex;
    line-height: 32px;
    align-items: center;
    justify-content: center;
    .ant-image{
      line-height: 32px;
      .ant-image-img{
        line-height: 32px;
      }
    }
  }
  a.ef-sidefun{
    padding: 0px !important;
    // height: 24px !important;
  }
  .ef-sidefun:hover{
    background: rgba(24,128,222,0.15);
    color: #ffffff;
  }

  // table 
  .ef-table{
    overflow: hidden;
    border-radius: 4px 4px 0px 0px;
    .ant-table-cell{
      padding: 8px 8px 8px 32px !important;
    }
    .ant-table-cell-fix-right.ant-table-cell{
        padding: 8px 8px 8px 8px !important;
    }
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
        content: none;
    }
    .custom-table-cell {
        white-space: pre-line; /* 或者使用 white-space: normal; */
    }
  }

  // modal
  .ef-modal{
    .ant-modal-content{
      border-radius: 4px;
      .ant-modal-body{
        padding: 32px;
        width: 100%;
        .ant-btn{
          border-radius: 4px;
        }
        .ant-btn-primary{
          background: #1880DE;
        }
      }
      .ant-modal-header{
        padding: 16px 32px;
      }
      .ant-modal-footer{
        padding: 16px 32px;
      }
    }
  }

  // modalform
  .ef-modalform-single-col{
    overflow: auto;
    display: flex;
    flex-direction: column;
    .ant-form-item{
      flex: 1;
      margin-bottom: 24px;
      .ant-form-item-label{
        padding-bottom: 8px;
      }
    }
    .ant-input-affix-wrapper{
      border-radius: 4px;
      border: 1px solid #E6E8EB;
    }
    .ant-picker{
      width: 100%;
      border-radius: 4px;
      border: 1px solid #E6E8EB;
    }
  }

  .ef-modalform-twin-col{
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .ant-form-item{
      width: 50%;
      width: calc(50% - 10px);
      margin-bottom: 24px;
      .ant-form-item-label{
        padding-bottom: 8px;
      }
    }   
    .ant-input-affix-wrapper{
      border-radius: 4px;
      border: 1px solid #E6E8EB;
    }
    .ant-picker{
      width: 100%;
      border-radius: 4px;
      border: 1px solid #E6E8EB;
    }
    .ant-form-item:nth-of-type(odd){
      margin-right: 20px;
    }
  }
  
  // style
  .ef-border-standard {
    border-radius: 4px;
  }
  .ef-border-module {
    border-radius: 8px;
  }

  // 对Input/Select/datePicker/以及普通元素统一控制
  .ef-form-element-normal{
    height: 32px;
    border-radius: 4px;
    border: 1px solid #E6E8EB;
    background: #ffffff;
    box-sizing: border-box;
    color: #121736;
    .ant-select-selector{
      height: 30px !important;
      border: none !important;
      padding: 0px 4px;
      width: 100%;
      .ant-select-selection-item{
        line-height: 32px;
      }
      .ant-select-selection-overflow-item{
        .ant-select-selection-item{
          height: 24px;
          line-height: 24px;
        } 
      }
      .ant-select-selection-search{
        .ant-select-selection-search-input{
          height: 30px;
          line-height: 30px;
        }
      }
    }
    .ant-input{
      height: 30px !important;
      border: none !important;
      line-height: 32px;
    }
  }
  .ef-form-element-normal.ant-input-affix-wrapper{
    .ant-input{
      border: none !important;
      line-height: 22px;
    }
  }
  label.ef-form-element-normal{
    line-height: 32px;
    border: none;
    color: #888B9A;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p.ef-form-element-normal{
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    background: #ffffff;
    color: #888B9A;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ef-form-element-block {
    height: 40px;
    border-radius: 4px;
    border: 1px solid #E6E8EB;
    background: #ffffff;
    box-sizing: border-box;
    color: #121736;
    .ant-select-selector{
      height: 38px !important;
      border: none !important;
      padding: 0px 4px;
      width: 100%;
      .ant-select-selection-item{
        line-height: 38px;
      }
      .ant-select-selection-overflow-item{
        .ant-select-selection-item{
          height: 32px;
          line-height: 32px;
        } 
      }
      .ant-select-selection-search{
        .ant-select-selection-search-input{
          height: 38px;
          line-height: 38px;
        }
      }
    }
    .ant-input{
      height: 30px !important;
      border: none !important;
      line-height: 32px;
    }
  }
  .ef-form-element-normal.ant-input-affix-wrapper{
    .ant-input{
      border: none !important;
      line-height: 30px;
    }
  }
  label.ef-form-element-block{
    line-height: 40px;
    border: none;
    color: #888B9A;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p.ef-form-element-block{
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    background: #ffffff;
    color: #888B9A;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Drawer 
  .ef-drawer{
    .ant-drawer-content-wrapper{
      width: 784px !important;
      .ant-drawer-header{
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .ant-typography{
          margin: 0;
        }
      }
      .ant-drawer-body{
        padding: 24px;
      }
      .ant-drawer-footer{
        height: 60px;
        padding: 10px 16px;
      }
    }
  }

  // Tab(card)
  .ef-tabs-card.ant-tabs-card{
    height: 100%;
    margin-bottom: 24px;
    > .ant-tabs-nav{
        width: 230px;
        .ant-tabs-tab{
          width: 200px;
          border: none;
          border-radius: 8px;
          height: 48px;
          background-color: #ffffff;
        }
        .ant-tabs-tab-active{
          border-radius: 8px !important;
          background: #E7F2FB;
          position: relative;
        }
        .ant-tabs-tab-active::after{
          content: '';
          box-shadow: -3px 0 15px rgba(0, 0, 0, 0.1);
          position: absolute;
          top: 50%;
          right: -39px;
          transform: translateY(-50%) rotate(45deg);
          width: 18px;
          height: 18px;
          background-color: #fff;
        }
      }
    > .ant-tabs-content-holder{
        border-left: none;
        margin-top: -24px;
        margin-bottom: -24px;
        box-shadow: -3px 0 15px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        >.ant-tabs-content.ant-tabs-content-left{
          background-color: #ffffff;
          border-radius: 8px;
          >.ant-tabs-tabpane.ant-tabs-tabpane-active{
            padding: 24px;
          }
        }
      }
  }

  // Tab(line)
  .ef-tabs-line.ant-tabs{
    > .ant-tabs-nav{

    }
    > .ant-tabs-nav::before{
      border: none;
    }
    > .ant-tabs-content-holder{
      padding: 0px;
    }
  }

  // filter平铺
  .ef-filter-flat{
    // margin-bottom: 48px;
    width: 100%;
    margin-bottom: 32px;
    padding-bottom: 32px;
    .ef-filter-section-one{
    
    }
    .ef-filter-title{
      margin-bottom: 18px;
    }
    .ef-filter-item{
      display: inline-block;
      padding: 6px 16px 6px 16px;
      height: 32px;
      border-radius: 16px;
      border: 1px solid #dee9ee;
      background-color: #ffffff;
      color: #9c9eb1;
      font-size: 14px;
      cursor: pointer;
      margin-right: 8px;
      margin-bottom: 8px;
      transition: background-color 0.2s ease-in,color 0.2s ease-in,border 0.2s ease-in;
    }
    .ef-filter-item-active{
      color: #1880DE;
      background-color: #e7f2f8;
      border: 1px solid #1880DE;
    } 
  }
  .ef-filter-flat:not(:last-child) {
    border-bottom: 1px solid #E6E8EB;
  }

  // Tabs
  .ef-tab{
    border-radius: 4px;
  }

  // radio 
  .ef-radio-small{
    display: flex;
    flex-wrap: wrap;
    .ant-radio-wrapper{
      height: 22.5px;
      margin-right: 0px;
      white-space: nowrap;
      color: #595C72;
      display: flex;
      flex-direction: row;
      align-items: center;
      .ant-radio{
        top: 0em;
        .ant-radio-inner{
          width: 12px;
          height: 12px;
        }
      }
      >span{
        white-space: normal;
        font-size: 14px;
        display: inline-block;
        line-height: 14px !important;
      }
    }
    .ant-radio-wrapper.ant-radio-wrapper-checked{
      color: #121736;
    }
  }

  // 可设为ef-container的父组件,将其多个子元素都可设置相对屏幕大小的最小尺寸
  .ef-container-father{
    height: calc(100% - 24px);
  }


@primary-color: #1880DE;