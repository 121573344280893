.user-list-container{
    padding: 0px;
    .result-table{
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
    }
}

.ef-user-list{
    width: 100%;
    .ef-userlist-table{
        .ant-table-cell{
            padding: 8px 8px 8px 32px !important;
        }
        .ant-table-cell-fix-right.ant-table-cell{
            padding: 8px 8px 8px 8px !important;
        }
        .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
            content: none;
        }
        .custom-table-cell {
            white-space: pre-line; /* 或者使用 white-space: normal; */
        }
    }
}


@primary-color: #1880DE;