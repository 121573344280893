.analyst-client-list-container{
    padding: 20px;
}

.analyst-client-container{
    .ant-table-cell{
        padding: 8px 8px 8px 32px !important;
    }
    .ant-table-cell-fix-right.ant-table-cell{
        padding: 8px 8px 8px 8px !important;
    }
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
        content: none;
    }
    .custom-table-cell {
        white-space: pre-line; /* 或者使用 white-space: normal; */
    }
}
@primary-color: #1880DE;