.ef-box{
    .box-list-form{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .ef-box-list-title{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #EBEDF2;
            padding-bottom: 16px;
            .box-list-bar{
                width: 4px; 
                height: 16px;
                border-radius: 2px;
                margin-right: 11px;
            }
            .box-list-title{
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .ef-box-section{
            flex: 1;
            width: 100%;
            background: #FFFFFF;
            padding: 20px 0px; 
            display: block;
            font-size: 14px;
            overflow-y: visible;
            overflow-x: hidden;
            .box-section{
                display: flex;
                flex-direction: row;
                .item-subtitle{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-right: 24px;
                    .item-subtitle-container{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        p{
                            color: #121736;
                            white-space: nowrap;
                        }
                    }
                    .item-subtitle-default{
                        height: 32px;
                    }
                }
                .item-label{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-right: 24px;
                    .item-label-container{
                        display: flex;
                        flex-direction: column;
                        p{
                            color: #888B9A;
                            white-space: nowrap;
                        }
                    }
                    .edit:not(:last-child){
                        margin-bottom: 12px;
                    }
                }
                .item-value{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    overflow-y: hidden;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    .item-value-container{
                        p{     
                            color: #121736;
                            white-space: pre-wrap;
                        }
                        .input-text{
                            border-radius: 4px;
                            border: 1px solid #EBEDF2; 
                            box-sizing: border-box;
                            color: #121736;
                        }
                        .input-textarea{
                            padding-top: 7px;
                            padding-bottom: 7px;
                            min-height: 80px;
                            line-height: 20px;
                            resize: vertical !important;
                        }
                        .item-value-select{
                            .ant-select-selector{
                                width: 100%;
                            }
                        }
                        .item-value-datepicker{
                            
                        }
                        .item-value-checkbox{
                            
                        }
                    }
                    .edit:not(:last-child){
                        margin-bottom: 12px;
                    }
                }
            }
            .default-item{
                line-height: 32px;
                height: 32px;
            }
            .default-overflow {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .item-textarea{
                display: flex;
                // max-height: 80px;
                height: auto;
                padding-top: 12px;
                padding-bottom: 6px;
                line-height: 20px;
                white-space: pre-wrap !important; 
            }
            .mutiple-wrap{
                border-radius: 8px;
                border: 1px solid #EBEDF2;
                padding: 12px 24px;
                margin-bottom: 16px;
            }
            .mutiple-wrap:last-child{
                margin-bottom: 0px;
            }
        }
    }
}


@primary-color: #1880DE;