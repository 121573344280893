@bodyWidth   : 480px;
@loginPadding: 10px;
@inputWidth  : 240px;
@errorWidth  : 300px;
@bodyLeft    : (@bodyWidth/2) - (@inputWidth/2) - @loginPadding;

.loginContainer {
    height          : 100%;
    width           : 100%;
    background-color: #57c2e7;
    position        : relative;

    .lgoinBody {
        width           : @bodyWidth;
        position        : absolute;
        top             : 50%;
        left            : 50%;
        transform       : translate(-50%, -50%);
        background-color: white;
        border-radius   : 10px;
        box-shadow      : 4px 6px 10px rgba(0, 0, 0, 0.16);
        padding         : @loginPadding;

        .header {
            margin        : auto;
            padding-top   : 32px;
            padding-bottom: 32px;

            .logo {
                display: block;
                margin : auto;
            }
        }

        .errorContainer {
            margin: auto;
            width : @errorWidth;
        }

        .body {
            margin: auto auto auto @bodyLeft;

            // width: @inputWidth;
            .item {
                margin: auto;
                height: 70px;

                .inputTitle {
                    margin-bottom: 5px;
                }

                .codeItem {
                    display: inline;

                }

                .input {
                    display: inline;
                    width  : @inputWidth;
                }

                .linkButton {
                    color: #57c2e7;
                }

                .passContainer {
                    width: @inputWidth;
                }
            }
        }

        .commit {
            margin-top: 20px;

            .submitButton {
                margin          : auto;
                width           : 50%;
                display         : block;
                background-color: #57c2e7;
                border-radius   : 5px;
                border-width    : 0px;
                color           : white;
            }

            .newButton {
                margin-top: 5px;
                color     : #57c2e7;
            }

            .jumpButton {
                margin-top: 20px;
                color     : #57c2e7;
            }
        }
    }

    .footer {
        width     : 100%;
        position  : absolute;
        bottom    : 39px;
        text-align: center;
        color     : white;
    }
}


.initContain{
    height          : 100%;
    width           : 100%;
    position        : relative;
    background: #c3e2ff;
    overflow: hidden;
    .imageBackground{
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    .login-tabs{
        position: absolute;
        top: 32px;
        right: 32px;
        box-shadow: 0px 8px 20px 0px rgba(24,128,222,0.1);
        background: rgba(255,255,255,0.4);
        border-radius: 4px;
        width: 143px;
        height: 48px;
        .login-language{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .login-language-text{
                display: inline-block;
                height: 22px;
                font-size: 16px;
                font-weight: 500;
                color: #595C70;
            }
            .login-language-icon{
                display: inline-block;
                width: 12px;
                height: 20px;
                margin-left: 8px;
                color: #595C70;
            }
        }
    }
    .input-section{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 512px;
        // height: 560px;
        background: #ffffff;
        box-shadow: 0px 8px 20px 0px rgba(24,128,222,0.1);
        border-radius: 12px;
        border: 1px solid #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .header{
            margin        : 0px auto;
            padding-top   : 48px;
            padding-bottom: 48px;

            .logo {
                display: block;
                margin : auto;
            }
        }
        .body{
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .error{
                width: 400px;
                // min-height: 48px;
                border-radius: 4px;
                border: 1px solid #FF5A5A;
                background: rgba(255,90,90,0.1);
                box-sizing: border-box;
                padding: 12px 16px 12px 16px;
                font-size: 16px;
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                .error-tips{
                    width: 16px;
                    height: 16px;
                }
                .error-desc{
                    font-size: 16px;
                    line-height: 20px;
                    color: #121736;
                    margin-left: 12px;
                }
            }
            .input-item{
                .input-title{
                    font-size: 16px;
                    color: #121736;
                    line-height: 18px;
                    margin-bottom: 12px;
                }
                .input-content{
                    width: 400px;
                    height: 48px;
                    border-radius: 4px;
                    border: 1px solid #E6E8EB;
                    box-sizing: border-box;
                    padding-left: 20px;
                    font-size: 16px;
                    color: #121736;
                    cursor: text;
                    .input-content-suffix{
                        line-height: 48px;
                        margin: 0px;
                        color: #1880DE;
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }
            .input-item:not(:first-child){
                margin-top: 28px;
            }
            .login-up{
                width: 400px;
                height: 48px;
                background: #1880DE;
                border-radius: 4px;
                margin-top: 28px;
                margin-bottom: 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .input-button{
                    width: 400px;
                    height: 48px;
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    color: #ffffff;
                    font-weight: bold;
                    font-size: 18px;
                    border: none;
                    line-height: 48px;
                }
            }
            .login-other{
                width: 400px;
                height: 48px;
                margin-top: 12px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 40px;
                .login-other-reset{
                    color: #888B9A;
                    font-size: 16px;
                    margin: 0;
                }
                .login-other-sign{
                    color: #1880DE;
                    font-size: 16px;
                    margin: 0;
                }
            }
        }
    }
    .footer{
        width     : 100%;
        position  : absolute;
        bottom    : 39px;
        text-align: center;
        color     : white;
    }
}

input:-internal-autofill-selected{
    background-color: -internal-light-dark(rgb(255,255,255)) !important;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important; /* 自定义阴影颜色 */
    -webkit-text-fill-color: #333 !important; /* 自定义文本颜色 */
}
.ant-input:focus{
    box-shadow: none !important;
}
@primary-color: #1880DE;