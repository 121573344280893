.ef-header-top-container{
    height: 64px;
    width: 100%;
    background-color: white;
    padding-left: 10px;
    z-index: 999;
    position: fixed; 
    box-shadow: 0px 6px 8px 0px rgba(24,128,222,0.1);

    .ef-header-left-container{
        display: inline-block;
        width: 256px;
        height: 100%;
        .logo-wrapper {
            height       : 64px;
            width        : inherit;
            left         : 0;
            top          : 0;
            text-align   : center;
            margin-top   : auto;
            margin-bottom: auto;
            background: #ffffff;
            .logo-img {
                max-height    : 80%;
                max-width     : 80%;
                transform     : translateY(0%);
            }
        }
    }

    .ef-header-right-container{
        position: absolute;
        // display: inline-block;
        right: 0px;
        .ef-username{
            display: inline-block;
            height: 64px;
            line-height: 64px;
            margin-right: 4px;
        }
        .ef-language{
            height: 64px;
            margin-right: 32px;
            cursor: pointer;            
            font-weight: 500;
            color: #121736;
            font-size: 14px;
            line-height: 64px;
            .ef-language-select-icon{
                font-size: 10px;
                color: #888B9A;
            }
        }
        .ef-user{
            height: 20px;
            margin-right: 43px;
            line-height: 64px;
            font-weight: 500;
            color: #121736;
            font-size: 14px;
            cursor: pointer;
            .ef-language-select-icon{
                font-size: 10px;
                color: #888B9A;
            }
        }
        .ant-image{
            top: -1px;
        }
    }

    .ef-header-logout-button{
        border-color: #57c2e7;
        color: #57c2e7;
        border-radius: 5px;
    }
}

.ant-layout-header{
    padding: 0 !important;
}
@primary-color: #1880DE;