.container {
    width     : 100%;
    min-height: 100vh;
    background: linear-gradient(rgb(222, 240, 255), rgb(245, 247, 250));

    .content-container-normal {
        background: transparent;
        padding-left: 288px;
           // open一下
        // background: linear-gradient(rgb(222, 240, 255), rgb(245, 247, 250));
    }

    .content-container-small {
        margin-left: 80px;
        background: transparent;
    }
}
@primary-color: #1880DE;