.setting-container{
    padding: 20px;
    p{
        display: inline;
    }
    .item-wrapper{
        margin-bottom: 20px;
    }

    .item-label-container{
        display: inline;
        width: 200px;
        float:left;
    }
    .item-content-container{
        display: inline;
        font-weight: bolder;
    }

    .acition-container{
        padding: 10px;
    }
    .update-button{
        width: 100px;
    }

    .input-text{
        // display: inline;
        // background-color: #f1f1f1;
        width: 40%;
    }
    .code-text{
        width: 100px;
    }
    .get-code-text{
        display: inline;
    }
}


.ef-box-footer{
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-direction: start;
    font-size: 14px;
    font-weight: 400;
    .ant-form-item{
        height: 28px;
    }
}
@primary-color: #1880DE;