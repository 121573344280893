.hint-container {
    border-radius      : 5px;
    border-top-width   : 2px;
    border-right-width : 2px;
    border-left-width  : 22px;
    border-bottom-width: 2px;
    border-color       : #EFDA96;
    height             : 150px;
    border-style       : solid;
    box-shadow   : 3px 4px 4px rgba(0, 0, 0, 0.16);

    .left-container {
        height : 100%;
        width  : 20px;
        display: inline-block;
    }

    .right-container {
        margin        : 20px;
        display       : inline-block;
        height        : 100%;
        vertical-align: top;
    }

    .hint-contain {
        font-weight: bolder;
        font-size  : 25px;
    }
}

.home-yellow {
    border-color: #EFDA96;
}


.home-blue {
    border-color: #96D8EF;
}

.home-green {
    border-color: #ABEF96;
}


.home-pink {
    border-color: #FFBCBC;
}
@primary-color: #1880DE;