.ef-conditionbar{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .search-item-container{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 10px;
        .search-item-son{
            display: block;
            height: 40px;
            line-height: 40px;
            text-overflow: ellipsis;
            white-space: nowrap; /* 禁止换行 */
            overflow: hidden; /* 溢出内容隐藏 */
        }
    }
}
@primary-color: #1880DE;