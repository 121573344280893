.trademark-container {
    padding: 20px;

    p {
        display: inline;
    }

    .item-wrapper {
        margin-bottom: 20px;
    }

    .item-label-container {
        display: inline;
        width  : 200px;
        float  : left;
    }

    .item-long-label-container {
        display: inline;
        width  : 400px;
        float  : left;
        margin-bottom: 5px;
    }


    .item-required-label-container {
        display: inline;
        width  : 200px;
        float  : left;
    }

    .item-required-label-container::after{
        content:"*";
        color: red;
    }

    .item-content-container {
        display    : inline;
        font-weight: bolder;
    }

    .avatar-uploader {
        width: 300px;
        height: 104px;      
    }

    .acition-container {
        padding: 10px;
    }

    .update-button {
        width      : 100px;
        margin-left: 12px;
    }

    .input-text {
        // display: inline;
        // background-color: #f1f1f1;
        width: 240px;
    }

    .select-box {
        // display: inline;
        // background-color: #f1f1f1;
        width: 240px;
    }

    .search-site {
        margin-left: 10px;
    }

    .description-text {
        height: 100px;
    }

}

.avatar-uploader{
    .ant-upload{
        border-radius: 6px;
        .ef-upload-desc{
            color: #121736;
            font-weight: 400;
        }
    }
}

.ef-trademark-detail-container{
    .ef-edit-section{
        .ef-edit-section-title{
            color: #121736;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 34px;
            .color-bar{
                width: 4px;
                height: 16px;
                background: #1880DE;
                border-radius: 2px;
                margin-right: 12px;
            }
        }
        .ef-title-border{
            padding-bottom: 24px;
            border-bottom: 1px solid #EBEDF2;
        }
        .ef-edit-section-column-left{
            padding-right: 40px;
        }
        .ef-edit-section-column-right{
            padding-left: 40px;
        }
        .edit-item{
            margin-bottom: 12px;
        }
        .item-value{
            color: #121736;
        }
        .item-long-label-container {
            display: inline;
            width  : 400px;
            float  : left;
            margin-bottom: 5px;
        }
        .description-text {
            height: 76px;
        }
        .update-button{
            margin-top: 24px;
        }
        .marks{
            color: red;
        }
        .description{
            margin-top: 10px;
            margin-bottom: 20px;
            font-size: 14px;
            color: #888B9A;
            white-space: normal;
            line-height: 20px;
        }
    }
    .ef-edit-section:not(:last-child){
        margin-bottom: 56px;
    }
}

.trademark-table-header{
    float: right;
    margin-bottom: 18px;
}
@primary-color: #1880DE;