.ef-home-chart-box-container{
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #EBEDF2;
    display: flex;
    flex-direction: column;
    .ef-chart-title-section{
        width: 100%;
        height: 87px;
        font-size: 24px;
        font-weight: 600;
        padding: 27px 36px 27px 36px;
        overflow: hidden;
        .ef-chart-title{
            color: #121736;
            line-height: 33px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .ef-chart-content{
        flex: 1;
        width: 100%;
        height: 100%;
        padding: 26px 36px 30px 36px;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        .ef-chart-bar-light{
            width: 8px;
            height: 83px;
            border-radius: 5px;
            display: inline-block;
        }
        .ef-chart-bar-data{
            margin-left: 20px;
            height: 83px;
            display: inline-block;
            overflow: hidden;
            .ef-chart-num{
                height: 48px;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                .ef-chart-number{
                    font-size: 40px;
                    line-height: 48px;
                    font-family: Bebas;
                    color: #121736;
                    display: inline-block;
                }
                .ef-chart-unit{
                    font-weight: 400;
                    color: #121736;
                    line-height: 28px;
                    font-size: 20px;
                    height: 28px;
                    display: inline-block;
                    margin-left: 14px;
                }
            }
            .ef-chart-desc{
                margin-top: 12px;
                height: 28px;
                font-size: 20px;
                font-weight: 400;
                color: #888B9A;
                line-height: 28px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
@primary-color: #1880DE;