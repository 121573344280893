.home-container {
    padding: 20px;
    .header-number-container {
        padding: 15px;
    }
    .margin-left-gap{
        margin-right: 10px;
    }
    .chart-element {
        height: 240px;
    }

    .news-element {
        color: black;
    }
    .news-item{
        color: black;
    }
}
@primary-color: #1880DE;