
.ef-container {
  color: #ffffff;
  border-radius: 8px;
  background: #ffffff;
  overflow: auto;
  box-shadow: 0px 0px 12px 0px rgba(39,120,193,0.1);
  box-sizing: border-box;
  position: relative;
  width: 100%;
  transition: min-height 0.3s ease-in-out,height 0.3s ease-in-out;
  .ef-container-content-wrap{

    > .ef-tabs-card{
      z-index: 2;
      .ef-tabs-container{
        position: relative;
        .ef-tabs-item {
          display: inline-block;
          position: relative;
          z-index: 2;
          margin-right: 8px;
          min-width: 132px;
          padding: 0px 24px;
          text-align: center;
          line-height: 42px;
          height: 41px;
          box-sizing: border-box;
          border-radius: 8px 8px 0px 0px;
        }
        .ef-tabs-item:hover{
          color: #1880DE;
        }
        .ef-tabs-item-active {
          height: 42px;
          font-size: 16px;
          background: #ffffff;
          border-top: 1px solid #f0f2f5;
          border-right: 1px solid #f0f2f5;
          border-bottom: 1px solid #ffffff;
          border-left: 1px solid #f0f2f5;
          color: #1890ff;
          text-shadow: 0 0 0.9px currentcolor;
          // box-sizing: border-box;
        }
        .ef-tabs-item-deactive {
          background: #f4f5f9;
          border-radius: 8px 8px 0px 0px;
          font-size: 16px;
          color: #595c72;
        }
        .ef-tabs-item-disable{
          cursor: not-allowed !important;
          opacity: 0.5 !important;
        }
      }
    }

    > .ef-tabs-line{
      .ef-tabs-container{
        .ef-tabs-item {
          display: inline-block;
          line-height: 41px;
          height: 41px;
          // padding: 0 40px 0 0px;
          margin: 0 40px 0 0px;
          font-size: 16px;
        }
        .ef-tabs-item:hover{
          color: #1880DE;
        }
        .ef-tabs-item-active{
          color: #1890ff;
          font-size: 16px;
          text-shadow: 0 0 0.9px currentcolor;
        }
        .ef-tabs-item-deactive {
          font-size: 16px;
          color: #595c72;
        }
        .ef-tabs-item-disable{
          cursor: not-allowed !important;
          opacity: 0.5 !important;
        }
      }
    }
  
    > .ef-tabs {
      height: 42px;
      display: block;
      .ef-tabs-container {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        .ef-users {
          float: right;
          height: 42px;
          line-height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .ef-side{
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 42px;
          // line-height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          .ef-header-button{
            color: #595C72;
            border-radius: 5px;
            .ant-image{
              top: -1px;
            }
          }
          .ef-header-button:hover{
            color: #1880DE;
            // background: ;
          }
          .ef-header-button:focus{
            color: #1880DE;
            background: #ffffff;
          }
        }
        .ef-tabs-slide {
          position: absolute;
          z-index: 2;
          bottom: 0px;
          width: 32px;
          height: 3px;
          background: #1880de;
          transition: left 0.3s ease-in-out;
        }
        .ef-tabs-slide-wait{
          background: transparent;
        }
        .ef-tabs-item:hover {
          cursor: pointer;
        }
      }
    }
  }

  > .ef-tabs-card-line{
    height: 1px;
    width: 100%;
    position: relative;
    top: 42px;
    z-index: 1;
    background: #f0f2f5;
  }

  .ef-content {
    width: 100%;
    box-sizing: border-box;
  }
  .ef-container-empty{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    .ef-container-empty-title{
      height: 26px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #121736;
      line-height: 26px;
    }
    .ef-container-empty-text{
      height: 16px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #888B9A;
      line-height: 16px;
    }
  }
}


.ef-tabs-cover{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:#ffffff;
  line-height: 42px;
  p{
    display:inline-block;
    line-height: 42px;
  }
}

.ef-container-empty{
  .ef-container-empty-title{
    display: block;
    margin-top: 12px !important;
    height: 26px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #121736;
    line-height: 26px;
  }
  .ef-container-empty-text{
    display: block;
    margin-top: 6px !important;
    height: 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888B9A;
    line-height: 16px;
  }
}

.auto-container{
  min-height: calc(100% - 101px);
  // 101 = breadContainer-56px + 全局下间距45px
}
@primary-color: #1880DE;