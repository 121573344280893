.ef-menu-container {
    // position: fixed;
    // width: auto;
    // bottom: 0px;
    padding-bottom   : 100px;
    // background-color: #000B16;
    // background-color: #ffffff;
}

.ef-side-container {
    // background-color: #000B16;
    // box-shadow         : 4px 6px 10px rgba(0, 0, 0, 0.16);
    // position        : fixed;
    z-index            : 1;
    overflow-y         : hidden;
}

.logo-wrapper {
    height       : 64px;
    width        : inherit;
    left         : 0;
    top          : 0;
    text-align   : center;
    margin-top   : auto;
    margin-bottom: auto;
    background: #ffffff;
}

.logo-img {
    max-height    : 80%;
    max-width     : 80%;
    vertical-align: middle;
    transform     : translateY(50%);
}

.logo-img-small {
    max-height    : 50%;
    max-width     : 50%;
    vertical-align: middle;
    transform     : translateY(50%);
}

.ef-submenu {
    // line-height: 40px;
    position        : absolute;
    background-color: #57c2e7;
    top             : 0px;
    left            : 0px;
    right           : 0px;
    bottom          : 0px;
    z-index         : 0;
    padding-left    : 24px;
    padding-right   : 16px;
    margin-top      : 4;
}

.ef-notice-badge {
    // 
    float: right;
    top  : 10px;
}

.ant-menu-inline{
    background: transparent !important;
}

.ant-menu-submenu-open.ant-menu-submenu-selected{
    .ant-menu-submenu-title{
        color: #0C3861;
    }
}

.ant-menu-item{
    a:hover{
        color: #0C3861;
    }
}

.ant-menu-root{
    >.ant-menu-item{
        margin-left: 8px;
        border-radius: 5px;
        width: 280px;
        padding-left: 24px !important;
        .ant-menu-title-content{
            margin-left: 16px !important;
        }
    }
    .ant-menu-submenu-inline{
        margin-left: 8px;
        width: 280px;
        .ant-menu-submenu-title{
            padding-left: 24px !important;
            .ant-menu-item-selected{
                background: #0C3861 !important;
            }
            .ant-menu-title-content{
                margin-left: 16px !important;
            }
        }
        .ant-menu-submenu-title:hover{
            color: #1880DE;
            .ant-menu-item-icon{
                color: #1880DE !important;
            }
        }
        .ant-menu-sub.ant-menu-inline{
            padding-left: 24px !important;
        }
    }
}

.ant-menu-vertical.ant-menu-inline-collapsed {
    >.ant-menu-item{
        border-radius: 5px;
        margin-left: 9px;
        width: 70px;
        padding-left: 28px !important;
        .ant-menu-item-icon{
            position: relative;
            left: -2px;
            top: 1px;
        }
    }
    .ant-menu-submenu{
        border-radius: 5px;
        margin-left: 9px;
        .ant-menu-item-icon{
            position: relative;
            left: -2px;
            top: 1px;
        }
        // width: 70px;
    }
    .ant-menu-title-content{
        margin-left: 16px;
        opacity: 0;
        transition: opacity 0.2s ease;
    }
}

.ant-menu-submenu.ant-menu-submenu-popup{
    .ant-menu-vertical{
        border-radius: 6px;
        padding: 12px !important;
        .ant-menu-item.ant-menu-item-only-child{
            padding: 0px 24px!important;
            border-radius: 5px;
            height: 48px;
            line-height: 48px;
            cursor: pointer;
        }
        .ant-menu-item:hover{
            background: #F4F5F9;
        }
    }
}

.ant-menu-sub{
    position: relative;
    left: -12px;
    .ant-menu-item{
        padding-left: 44px !important;
        box-sizing: border-box;
        border-radius: 5px;
        width: auto !important;
    }
}
.ant-menu-item-active{
    color: #1880DE !important;
    a:hover{
        color: #1880DE !important;
    }
    .ant-menu-item-icon{
        color: #1880DE !important;
    }
}

.ant-menu-item-selected{
    background: #0C3861 !important;
    // box-sizing: border-box;
    // border-radius: 5px;
    // width: auto !important;
    a{
        color: #ffffff;
    }
    a:hover{
        color: #ffffff;
    }
    .ant-menu-item-icon{
        color: #ffffff !important;
    }
}

.ant-menu-submenu.ant-menu-submenu-selected{
    .ant-menu-submenu-title{
        color: #1880DE;
        .ant-menu-item-icon{
            color: #1880DE !important;
        }
    }
}

.ant-menu-item-selected:hover{
    a:hover{
        color: #1880DE;
    }
    .ant-menu-item-icon{
        color: #1880DE !important;
    }
}

.ant-menu-item-selected::after{
    opacity: 0 !important;
}

.ant-menu-submenu-arrow{
    margin-right: 20px;
}
.ant-layout-sider-trigger{
    background: transparent;
}

.ef-side-container{
    background: transparent;
    overflow: auto;
    height: calc(100vh - 64px);
    margin-top: 64px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
       // open一下
    // background: linear-gradient(to bottom, #DEF0FF, #f5f7fa)
}

.ant-layout-sider-children{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 15px);
    height: auto;
}

.ef-footer-shrink {
    width: 249px;
    color: #888B9A;
    height: 56px;
    margin: 0px 20px 56px 20px;
    box-sizing: border-box;
    border-top: 1px solid #E6E8EB;
    display: flex;
    flex-direction: row;
    align-items: center;
    .ef-footer-flex{
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer
    }
    .menu-collapse{
        font-size: 20px;
    }
    .collapse-desc{
        margin-left: 8px;
        height: 20px;
    }
}

.ef-footer-grow {
    width: 40px;
    color: #888B9A;
    height: 56px;
    margin: 0px 20px 56px 20px;
    box-sizing: border-box;
    border-top: 1px solid #E6E8EB;
    display: flex;
    flex-direction: row;
    align-items: center;
    .ef-footer-flex{
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer
    }
    .menu-collapse{
        font-size: 20px;
    }
}
@primary-color: #1880DE;