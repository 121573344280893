.client-detail-container{
    padding: 20px;
    p{
        display: inline;
    }
    .item-wrapper{
        margin-bottom: 20px;
    }

    .item-label-container{
        display: inline;
        width: 180px;
        float:left;
    }
    .item-content-container{
        display: inline;
        font-weight: bolder;
    }

    .acition-container{
        padding: 10px;
    }
    .update-button{
        width: 100px;
    }

    .input-text{
        display: inline;
        // background-color: #f1f1f1;
        width: 60%;
    }
    .remarks-text{
        width: 60%;
        height: 100px;
        // background-color: #f1f1f1;
    }

    .analyst-select{
        min-width: 100px;
    }

    .date-row{
        margin-bottom: 5px;
    }
}

.client-detail-container-footer {

}
.client-detail-meau{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 72px;
    .client-detail-meau-item{
        display: inline-block;
        padding: 6px 16px;
        margin-right: 8px;
        border: 1px solid rgba(183,185,194,0.3);
        background-color: #FFFFFF;
        border-radius: 20px;
        white-space: nowrap;
        cursor: pointer;
    }
    .meau-item-active{
        color: #1880DE;
        border: 1px solid #1880DE;
        background-color: rgba(24,128,222,0.1);
        transition: background-color 0.2s ease-out,border 0.2s ease-out;
    }
}
@primary-color: #1880DE;